/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import Model from "react-body-highlighter";
import moment from "moment";
//components
import { HorizontalNavTab } from "app/shared/components/ReportModal";
import ClideLoader from "app/shared/ui-kit/loader";
// ui kit components
import {
  IconSettings,
  comboboxFilterAndLimit,
  Combobox,
  File,
  Files,
  Tooltip,
  Input,
  Datepicker,
} from "@salesforce/design-system-react";
//assets
import {
  PlusIconRed,
  RoundSave,
  CrossRound,
  RoundDelete,
  RoundEdit,
  TrashIcon as Trash,
} from "assets/Iconsv2/Iconsv2";
//constant
import { YearSelect, month } from "app/views/Ira/Detail/constants/constants";
import { useTranslation } from "react-i18next";
import { requiredMessage } from "app/shared/constants/Messages";

const DetailStep2 = ({ state, functionContent, variablesObj }) => {
  const {
    isInprogress,
    isApproveEnabled,
    iraDetailStep2,
    iraDetailStep1,
    dataGathering,
    accountsWithIcon,
  } = variablesObj ?? {};
  const { investigation } = dataGathering ?? {};
  const {t} = useTranslation('ira');
  return (
    <div className="row mt-3">
      <div className="col-12 col-md-12 col-lg-12">
        <div className="col-12 col-lg-12 col-md-12  px-0">
          <div className="row ">
            <div className="col-12 col-lg-4 col-md-4 px-0">
              <div className="clide-v2-title mb-1">
                <label className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                  {" "}
                  {t('step2InitialInvestigation')}
                </label>
              </div>
            </div>
            <div className="col-12 col-lg-8 col-md-8 d-flex justify-content-end px-0">
              <div className="pb-2 overflow-auto">
                {investigation.isAddEnable && investigation.editId === "" ? (
                  <HorizontalNavTab
                    tabs={
                      state.injuredPersonList.length
                        ? state.injuredPersonList.map((el) => {
                            return {
                              tabName: el?.label,
                              key: el?.id,
                            };
                          })
                        : [{ tabName: "Other", key: "other" }]
                    }
                    activeItem={investigation?.injuredPersonType}
                    handleChange={(key) =>
                      functionContent.manageDataGatheringParams(
                        "investigation",
                        {
                          injuredPersonType: key,
                          injured_person_name: key !== "other" ? key : "",
                        }
                      )
                    }
                  />
                ) : null}
              </div>
              {isInprogress && isApproveEnabled && !investigation.isAddEnable ? (
              <button
                className="clide-v2-icon-btn "
                onClick={() =>
                  functionContent.debounceFunc(() => {
                    functionContent.manageDataGatheringParams("investigation", {
                      isAddEnable: true,
                    });
                  })
                }
              >
                <PlusIconRed />
              </button>
            ) : null}
            </div>
          </div>

          
        </div>

        {investigation.isAddEnable && investigation?.injuredPersonType ? (
          <div className="border p-2 rounded row w-100">
            <div className="col-12 col-lg-10 col-md-10">
              <div className="row">
                <div className="col-12 col-lg-3 col-md-3">
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: (event, { selection }) => {
                        functionContent.manageDataGatheringParams(
                          "investigation",
                          {
                            proofType: selection,
                          }
                        );
                      },
                    }}
                    labels={{
                      label: "Add Identity Proof",
                      placeholder: "Select  Type",
                    }}
                    aria-required={true}
                    required
                    errorText={
                      state.stepValidations.isIdProofTypeError
                        ? "Please select the Identity Proof"
                        : null
                    }
                    options={state.iraidentityProof}
                    selection={investigation.proofType}
                    variant="readonly"
                  />
                </div>
                <div className="col-12 col-lg-3 col-md-3">
                  {investigation?.proofType !== "" ? (
                    <div className="slds-form-element  slds-has-error">
                      <span
                        className="slds-form-element__label"
                        id="file-selector-primary-label-101"
                      >
                        {t('idProof')}
                      </span>
                      <div className="slds-form-element__control">
                        <div className="slds-file-selector slds-file-selector_files">
                          <div className="slds-file-selector__dropzone">
                            <input
                              type="file"
                              className="slds-file-selector__input slds-assistive-text"
                              accept="image/png"
                              id="file-upload-input-103"
                              onChange={(e) =>
                                functionContent.generateTheIdProof(
                                  e,
                                  investigation.proofType[0]["id"]
                                )
                              }
                              aria-labelledby="file-selector-primary-label-101 file-selector-secondary-label102"
                            />
                            <label
                              className="slds-file-selector__body mb-0"
                              for="file-upload-input-103"
                              id="file-selector-secondary-label102"
                            >
                              <span className="slds-file-selector__button slds-button slds-button_neutral clide-v2-fs-5">
                                {/* <Upload className=" slds-button__icon slds-button__icon_left cursor-pointer mx-3" /> */}
                                {t('uploadId')}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {state.stepValidations.isIdProofDocumentError ? (
                        <div className="slds-form-element__help" id="error-108">
                          {t('idProofDoc')}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  {investigation.identity_proof?.length ? (
                    <Scrollbars
                      style={{
                        height: "8rem",
                        maxWidth: "15rem",
                      }}
                      renderView={(props) => (
                        <div
                          style={{
                            paddingRight: "15px",
                            paddingBottom: "1rem",
                            ...props.style,
                            overflowX: "hidden",
                          }}
                        />
                      )}
                    >
                      <div className="mt-3 mr-1">
                        <Files
                          id="files-loading-example"
                          className="salseforce-image-perview"
                        >
                          {investigation.identity_proof?.map((el, idx) => {
                            if (Object.hasOwn(el, "category_id")) {
                              return (
                                <File
                                  id={`${idx} - demo-id`}
                                  labels={{
                                    title: "Uploaded File",
                                  }}
                                  image={el?.proof}
                                  crop="1-by-1"
                                  className="w-100"
                                />
                              );
                            }
                            return (
                              <File
                                id={`${idx} - ${el?.name}`}
                                key={idx}
                                labels={{
                                  title: el?.name,
                                }}
                                image={el?.proof}
                                crop="1-by-1"
                                className="w-100"
                              />
                            );
                          })}
                        </Files>
                      </div>
                    </Scrollbars>
                  ) : null}
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-lg-3 col-md-3">
                  <div className="d-flex">
                    <legend className="slds-form-element__legend slds-form-element__label">
                      <abbr className="slds-required" title="required">
                        *{" "}
                      </abbr>
                      {t('addPeople')}
                    </legend>
                    <div>
                      <Tooltip
                        id="help"
                        align="top left"
                        content={"Capture multiple witnesses statement"}
                        variant="learnMore"
                      >
                        <button
                          className="slds-button slds-button_icon"
                          aria-describedby="help"
                        >
                          {" "}
                          <svg
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            data-tip
                            data-for={`startDate`}
                          >
                            <path
                              d="M8.5 1.59375C7.13408 1.59375 5.79882 1.99879 4.6631 2.75766C3.52737 3.51653 2.64218 4.59514 2.11946 5.85709C1.59674 7.11904 1.45998 8.50766 1.72645 9.84734C1.99293 11.187 2.65069 12.4176 3.61655 13.3835C4.5824 14.3493 5.81298 15.0071 7.15266 15.2735C8.49234 15.54 9.88096 15.4033 11.1429 14.8805C12.4049 14.3578 13.4835 13.4726 14.2423 12.3369C15.0012 11.2012 15.4063 9.86593 15.4063 8.5C15.4043 6.66894 14.6761 4.91343 13.3813 3.61868C12.0866 2.32393 10.3311 1.59568 8.5 1.59375ZM8.23438 4.78125C8.39198 4.78125 8.54605 4.82799 8.6771 4.91555C8.80814 5.00311 8.91028 5.12756 8.97059 5.27317C9.03091 5.41878 9.04669 5.57901 9.01594 5.73359C8.98519 5.88817 8.9093 6.03016 8.79785 6.1416C8.68641 6.25305 8.54442 6.32894 8.38984 6.35969C8.23526 6.39044 8.07504 6.37466 7.92943 6.31434C7.78382 6.25403 7.65936 6.15189 7.5718 6.02084C7.48424 5.8898 7.4375 5.73573 7.4375 5.57812C7.4375 5.36678 7.52146 5.16409 7.6709 5.01465C7.82035 4.86521 8.02303 4.78125 8.23438 4.78125ZM9.03125 12.2188C8.74946 12.2188 8.47921 12.1068 8.27995 11.9076C8.08069 11.7083 7.96875 11.438 7.96875 11.1562V8.5C7.82786 8.5 7.69273 8.44403 7.5931 8.3444C7.49347 8.24477 7.4375 8.10965 7.4375 7.96875C7.4375 7.82785 7.49347 7.69273 7.5931 7.5931C7.69273 7.49347 7.82786 7.4375 7.96875 7.4375C8.25055 7.4375 8.5208 7.54944 8.72005 7.7487C8.91931 7.94796 9.03125 8.21821 9.03125 8.5V11.1562C9.17215 11.1562 9.30728 11.2122 9.4069 11.3118C9.50653 11.4115 9.5625 11.5466 9.5625 11.6875C9.5625 11.8284 9.50653 11.9635 9.4069 12.0632C9.30728 12.1628 9.17215 12.2188 9.03125 12.2188Z"
                              fill="#FF6347"
                            ></path>
                          </svg>
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="slds-form-element__control">
                    <div className="slds-checkbox">
                      <input
                        type="checkbox"
                        name="options"
                        id="checkbox-unique-id-163"
                        checked={
                          investigation?.employee_contractor_status === 1
                        }
                        onChange={() =>
                          functionContent.manageDataGatheringParams(
                            "investigation",
                            {
                              employee_contractor_status: 1,
                            }
                          )
                        }
                      />
                      <label
                        className="slds-checkbox__label"
                        for="checkbox-unique-id-163"
                      >
                        <span className="slds-checkbox_faux"></span>
                        <span className="slds-form-element__label ml-2 ">
                          {t('employee')}
                        </span>
                      </label>
                    </div>
                    <div className="slds-checkbox">
                      <input
                        type="checkbox"
                        name="options"
                        id="checkbox-unique-id-164"
                        checked={
                          investigation?.employee_contractor_status === 2
                        }
                        onChange={() =>
                          functionContent.manageDataGatheringParams(
                            "investigation",
                            {
                              employee_contractor_status: 2,
                              contractor_company_name: "",
                            }
                          )
                        }
                      />
                      <label
                        className="slds-checkbox__label"
                        for="checkbox-unique-id-164"
                      >
                        <span className="slds-checkbox_faux"></span>
                        <span className="slds-form-element__label ml-2 ">
                          {t('contractor')}
                        </span>
                      </label>
                    </div>
                    {state.stepValidations.isAddPeopleTypeError ? (
                      <div className="slds-form-element__help" style={{color : 'var(--slds-g-color-error-base-40, #ea001e)'}}>
                        {requiredMessage}
                      </div>
                    )
                    :
                    null}
                  </div>
                </div>

                {investigation?.employee_contractor_status === 2 ? (
                  <div className="col-12 col-lg-3 col-md-3">
                    <Input
                      assistiveText={{
                        label: "Contractor’s Company",
                      }}
                      id="assistiveLabel-id"
                      placeholder="Enter company name"
                      label={"Contractor’s Company"}
                      value={investigation?.contractor_company_name}
                      onChange={(e) =>
                        functionContent.manageDataGatheringParams(
                          "investigation",
                          {
                            contractor_company_name: e.target.value,
                          }
                        )
                      }
                    />
                  </div>
                ) : null}
                {investigation?.injuredPersonType === "other" ? (
                  <div className="col-12 col-lg-3 col-md-3">
                    <Input
                      assistiveText={{ label: "Name" }}
                      id="assistiveLabel-id"
                      placeholder="Enter name"
                      label={"Name of Injured Person"}
                      value={investigation?.injured_person_name}
                      onChange={(e) =>
                        functionContent.manageDataGatheringParams(
                          "investigation",
                          {
                            injured_person_name: e.target.value,
                          }
                        )
                      }
                    />
                  </div>
                ) : null}
                <div className="col-12 col-lg-3 col-md-3">
                  <Combobox
                    id="combobox-readonly-single-33"
                    events={{
                      onSelect: (event, { selection }) => {
                        functionContent.manageDataGatheringParams(
                          "investigation",
                          {
                            injured_person_gender: selection,
                          }
                        );
                      },
                    }}
                    labels={{
                      label: "Gender",
                      placeholder: "Select gender",
                    }}
                    options={state.genderList}
                    selection={investigation.injured_person_gender}
                    variant="readonly"
                  />
                </div>
                <div className="col-12 col-lg-3 col-md-3">
                  <Combobox
                    id="combobox-readonly-single-33"
                    events={{
                      onSelect: (event, { selection }) => {
                        functionContent.manageDataGatheringParams(
                          "investigation",
                          {
                            injured_type_id: selection,
                          }
                        );
                      },
                    }}
                    labels={{
                      label: "Injury Type",
                      placeholder: "Select type",
                    }}
                    options={state.injurtType}
                    selection={investigation.injured_type_id}
                    variant="readonly"
                  />
                </div>

                <div className="col-12 col-lg-3 col-md-3">
                  <Input
                    id="assistiveLabel-id"
                    placeholder="Enter age"
                    label={"Age"}
                    value={investigation?.injured_person_age}
                    onChange={(e) =>
                      functionContent.manageDataGatheringParams(
                        "investigation",
                        { injured_person_age: e.target.value }
                      )
                    }
                  />
                </div>
                <div className="col-12 col-lg-3 col-md-3">
                  <Combobox
                    id="combobox-readonly-single-33"
                    events={{
                      onSelect: (event, { selection }) => {
                        functionContent.manageDataGatheringParams(
                          "investigation",
                          {
                            person_work_type: selection,
                          }
                        );
                      },
                    }}
                    labels={{
                      label: "Person Working Type",
                      placeholder: t('selectType'),
                    }}
                    options={state?.iraWorkType}
                    selection={investigation.person_work_type}
                    variant="readonly"
                  />
                </div>
                <div className="col-12 col-lg-3 col-md-3">
                  <Datepicker
                    labels={{
                      label: "Joining Date",
                    }}
                    onChange={(event, data) => {
                      functionContent.manageDataGatheringParams(
                        "investigation",
                        {
                          joining_date: data.date,
                        }
                      );
                    }}
                    formatter={(date) => {
                      return date ? moment(date).format("M/D/YYYY") : "";
                    }}
                    value={investigation?.joining_date}
                  />
                </div>
                <div className="col-12 col-lg-3 col-md-3">
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: (event, { selection }) => {
                        functionContent.manageDataGatheringParams(
                          "investigation",
                          {
                            working_shift: selection,
                          }
                        );
                      },
                    }}
                    labels={{
                      label: "Working Shift",
                      placeholder: t('selectType'),
                    }}
                    options={state.workingShift}
                    selection={investigation.working_shift}
                    variant="readonly"
                  />
                </div>

                <div className="col-12">
                  {!functionContent._checkSettingParameter(
                    "month_year_with_this_employee"
                  ) ? (
                    <div className="row mt-2">
                      <div class="col-12 col-lg-12 col-md-12  px-0">
                        <div class="clide-v2-title mb-1">
                          <label class="clide-v2-fs-5 ">
                            {" "}
                            {t('monthsYearEmployee')}
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-lg-3 col-md-3">
                        <Combobox
                          id="combobox-readonly-single"
                          events={{
                            onSelect: (event, { selection }) => {
                              functionContent.manageDataGatheringParams(
                                "investigation",
                                {
                                  year_with_this_emp: selection,
                                }
                              );
                            },
                          }}
                          labels={{
                            label: "Year ",
                            placeholder:t('selectType'),
                          }}
                          options={functionContent.generateDropdownParameter(
                            YearSelect,
                            {
                              id: "id",
                              label: "name",
                            }
                          )}
                          selection={investigation.year_with_this_emp}
                          variant="readonly"
                        />
                      </div>
                      <div className="col-12 col-lg-3 col-md-3">
                        <Combobox
                          id="combobox-readonly-single"
                          events={{
                            onSelect: (event, { selection }) => {
                              functionContent.manageDataGatheringParams(
                                "investigation",
                                {
                                  month_with_this_emp: selection,
                                }
                              );
                            },
                          }}
                          labels={{
                            label: "Month",
                            placeholder: t('selectMonth'),
                          }}
                          options={functionContent.generateDropdownParameter(
                            month,
                            {
                              id: "id",
                              label: "name",
                            }
                          )}
                          selection={investigation.month_with_this_emp}
                          variant="readonly"
                        />
                      </div>
                    </div>
                  ) : null}

                  {!functionContent._checkSettingParameter(
                    "month_year_doing_this_job"
                  ) ? (
                    <div className="row mt-2">
                      <div class="col-12 col-lg-12 col-md-12  px-0">
                        <div class="clide-v2-title mb-1">
                          <label class="clide-v2-fs-5 ">
                            {" "}
                           {t('monthYearJob')}
                          </label>
                        </div>
                      </div>

                      <div className="col-12 col-lg-3 col-md-3">
                        <Combobox
                          id="combobox-readonly-single"
                          events={{
                            onSelect: (event, { selection }) => {
                              functionContent.manageDataGatheringParams(
                                "investigation",
                                {
                                  year_doing_this_job: selection,
                                }
                              );
                            },
                          }}
                          labels={{
                            label: "Year ",
                            placeholder: t('selectYear'),
                          }}
                          options={functionContent.generateDropdownParameter(
                            YearSelect,
                            {
                              id: "id",
                              label: "name",
                            }
                          )}
                          selection={investigation.year_doing_this_job}
                          variant="readonly"
                        />
                      </div>
                      <div className="col-12 col-lg-3 col-md-3">
                        <Combobox
                          id="combobox-readonly-single"
                          events={{
                            onSelect: (event, { selection }) => {
                              functionContent.manageDataGatheringParams(
                                "investigation",
                                {
                                  month_doing_this_job: selection,
                                }
                              );
                            },
                          }}
                          labels={{
                            label: "Month",
                            placeholder: t('selectMonth'),
                          }}
                          options={functionContent.generateDropdownParameter(
                            month,
                            {
                              id: "id",
                              label: "name",
                            }
                          )}
                          selection={investigation.month_doing_this_job}
                          variant="readonly"
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <IconSettings iconPath="/assets/icons">
                    <div className="mt-auto">
                      <Combobox
                        id="combobox-readonly-single"
                        events={{
                          onSelect: (event, { selection }) => {
                            functionContent.manageDataGatheringParams(
                              "investigation",
                              {
                                injured_parts: selection,
                              }
                            );
                          },
                          onRequestRemoveSelectedOption: (event, data) => {
                            functionContent.manageDataGatheringParams(
                              "investigation",
                              {
                                injured_parts: data.selection,
                              }
                            );
                          },
                        }}
                        labels={{
                          label: "Injured Body Part",
                          placeholder: t('selectPart'),
                        }}
                        multiple
                        options={comboboxFilterAndLimit({
                          inputValue: "",
                          limit: 9999,
                          options: accountsWithIcon,
                          selection: investigation.injured_parts ?? [],
                        })}
                        selection={investigation.injured_parts}
                        variant="readonly"
                      />
                    </div>
                  </IconSettings>
                </div>
                <div className="col-12 col-lg-6 col-md-6">
                  <Combobox
                    id="combobox-readonly-single"
                    events={{
                      onSelect: (event, { selection }) => {
                        functionContent.manageDataGatheringParams(
                          "investigation",
                          {
                            time_of_incident: selection,
                          }
                        );
                      },
                    }}
                    labels={{
                      label: "Time of Incident",
                      placeholder: t('selectTime'),
                    }}
                    options={state.iraTimeOfIncident}
                    selection={investigation.time_of_incident}
                    variant="readonly"
                  />
                </div>
                <div className="col-12  px-0">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 mt-2">
                      <Combobox
                        id="combobox-readonly-single"
                        events={{
                          onSelect: (_, { selection }) => {
                            functionContent.setState((prev) => {
                              return {
                                questionArray: [
                                  ...prev.questionArray,
                                  {
                                    question_id: selection[0].id,
                                    question: selection[0].label,
                                    response: "",
                                  },
                                ],
                              };
                            });
                          },
                        }}
                        labels={{
                          label: "Incidence Analysis Question (5Y Analysis)",
                          placeholder: t('selectQuestion'),
                        }}
                        options={functionContent.filterQuestion(
                          state.questionMaster,
                          state.questionArray
                        )}
                        // selection={investigation.injured_parts}
                        variant="readonly"
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                      <Scrollbars
                        style={{ height: "8rem" }}
                        renderView={(props) => (
                          <div
                            style={{
                              paddingRight: "15px",
                              paddingBottom: "1rem",
                              ...props.style,
                              overflowX: "hidden",
                            }}
                          />
                        )}
                      >
                        {state.questionArray.map((el, idx, arr) => (
                          <div className=" d-flex space-between mr-3 mt-2">
                            {" "}
                            <div className="slds-col_padded" key={idx}>
                              <h1 className="slds-text-title_caps slds-p-vertical_medium">
                                {el.question}
                              </h1>
                              <Input
                                assistiveText={{
                                  label: "My label",
                                }}
                                id={el?.question_id}
                                placeholder={t('enterResponse')}
                                value={el.response}
                                onChange={(e) =>
                                  functionContent.manageQuestion(
                                    e.target.value,
                                    arr,
                                    idx
                                  )
                                }
                              />
                            </div>
                            <div>
                              <button
                                className="cursor-p mx-2 clide-v2-icon-btn"
                                onClick={() =>
                                  functionContent.debounceFunc(() => {
                                    functionContent.setState({
                                      questionArray: arr.filter(
                                        (sub) =>
                                          sub.question_id !== el.question_id
                                      ),
                                    });
                                  })
                                }
                              >
                                <Trash className="cursor-pointer mx-3" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </Scrollbars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-2 col-md-2">
              <div className="d-flex space-between justify-content-end mt-2">
                {investigation.isLoading ? (
                  <div className=" mx-3 mt-3">
                    <ClideLoader />{" "}
                  </div>
                ) : (
                  <div>
                    <button
                      className="cursor-p  clide-v2-icon-btn"
                      onClick={() =>
                        functionContent.debounceFunc(() => {
                          functionContent.saveIntialInvestigation();
                        })
                      }
                    >
                      <RoundSave className="cursor-p " />
                    </button>
                    <button
                      className="cursor-p mx-2 clide-v2-icon-btn"
                      onClick={() =>
                        functionContent.debounceFunc(() => {
                          functionContent.manageDataGatheringParams(
                            "",
                            {},
                            true
                          );
                        })
                      }
                    >
                      <CrossRound />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {!state.addIntialResponse ? (
          <>
            {!investigation.isAddEnable &&
              iraDetailStep2?.injured_person_details?.person_details?.map(
                (el, idx) => (
                  <div className="border p-2 rounded row w-100">
                    <div className="col-12 col-lg-10 col-md-10" key={idx}>
                      <div className="row">
                        <div class="col-12 px-0">
                          <div className="row">
                            <div class="col-6  px-0">
                              <div class="clide-v2-title mb-1">
                                <label class="clide-v2-fs-4 mb-0">
                                  {t('injuredPer')}{" "}
                                </label>
                              </div>
                            </div>

                            <div class="col-6 d-flex justify-content-end px-0">
                              <div class="badge border clide-v2-title py-2">
                                <label class="mb-0">
                                  {el?.injured_person_name}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div class="col-12 col-lg-4 col-md-4">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {t('identityProof')}{" "}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            {el?.identity_proof?.length ? (
                              <>
                                <div>
                                  {functionContent.assetRender(
                                    el?.identity_proof[0]["proof"]
                                  )}
                                </div>
                                <div class=" my-2 text-center">
                                  {el?.identity_proof[0]["category"]}
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-12 col-lg-2 col-md-2">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">{t("type")} </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">{el?.employee_type}</label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-2 col-md-2">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">{t('gender')} </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">{el?.injured_person_gender}</label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-2 col-md-2">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">{t('age')} </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">{el?.injured_person_age}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-2 col-md-2">
                          <Model
                            style={{
                              maxWidth: "5vw",
                            }}
                            data={[
                              {
                                name: "Bench Press",
                                muscles: functionContent.getInjuredParts(el),
                              },
                            ]}
                            highlightedColors={["#EC6B15"]}
                          />
                        </div>
                        <div class="col-12 col-lg-3 col-md-3">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {" "}
                                {t('injuryType')}{" "}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">{el?.injury_type}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-3 col-md-3">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {" "}
                                {t('workShift')}{" "}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">
                              {el?.joining_details?.working_shift}
                            </label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-6 col-md-6">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {" "}
                                {t('injuredBodyPart')}{" "}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <Scrollbars
                              style={{
                                height: "3.8rem",
                                width: "22rem",
                              }}
                              renderView={(props) => (
                                <div
                                  style={{
                                    paddingRight: "15px",
                                    ...props.style,
                                    overflowX: "hidden",
                                  }}
                                />
                              )}
                            >
                              <div className="d-flex">
                                {el?.joining_details?.injured_parts?.map(
                                  (sub, idx) => (
                                    <span
                                      key={idx}
                                      className="badge border font-weight-500 p-2 mr-2 clide-v2-secondary-color"
                                    >
                                      {sub?.injured_parts}
                                    </span>
                                  )
                                )}
                              </div>
                            </Scrollbars>
                          </div>
                        </div>
                        <div class="col-12 col-lg-3 col-md-3">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {" "}
                                {t('incidentTime')}{" "}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">
                              {el?.joining_details?.time_of_incident}
                            </label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-3 col-md-3">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {" "}
                                {t('personWorkingType')}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">{el?.person_work_type}</label>
                          </div>
                        </div>
                        <div class="col-12 col-lg-3 col-md-3">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {" "}
                               {t('monthYearJobOverall')}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">
                              {
                                el?.joining_details
                                  ?.month_year_with_this_employee
                              }
                            </label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-3 col-md-3">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {" "}
                                {t('joiningDate')}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">{el?.joining_date}</label>
                          </div>
                        </div>

                        <div class="col-12 col-lg-3 col-md-3">
                          <div class="col-12 col-md-12 col-lg-12 px-0">
                            <div class="clide-v2-title mb-1">
                              <label class="clide-v2-fs-4 mb-0">
                                {" "}
                                {t('monthYearJobOverall')}
                              </label>
                            </div>
                          </div>
                          <div class="clide-v2-title mb-">
                            <label class="">
                              {el?.joining_details?.month_year_doing_this_job}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-12 col-md-12  px-0 text-center my-3">
                          <div className="clide-v2-title mb-1">
                            <label className="clide-v2-fs-4 mb-4 clide-v2-primary-color">
                              {" "}
                              {t('incidenceAnalysis')}
                            </label>
                          </div>
                        </div>
                        <div className="col-12 px-0">
                          {el?.incident_analysis_question?.map((el, idx) => (
                            <div className="row">
                              <div className="col-12  px-0">
                                <div class="clide-v2-title mb-1">
                                  <label class="clide-v2-fs-4 ">
                                    {t('q')} {idx + 1} - {el?.question}
                                  </label>
                                </div>
                                <div class="clide-v2-title mb-1">
                                  <label class="clide-v2-fs-4 fw-normal">
                                    {t('ans')} - {el?.response}
                                  </label>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {/* Edit Controls */}

                    <div className="col-12 col-lg-2 col-md-2">
                      <div className="d-flex space-between mt-2 justify-content-end">
                        {isInprogress && isApproveEnabled ? (
                          <div>
                            <button
                              onClick={() =>
                                functionContent.debounceFunc(() => {
                                  functionContent.mapInvesigationParams(el);
                                })
                              }
                              className="clide-v2-icon-btn mr-2"
                            >
                              <RoundEdit />
                            </button>
                          </div>
                        ) : null}
                        {isInprogress && isApproveEnabled ? (
                          <div>
                            <button
                              onClick={() =>
                                functionContent.debounceFunc(() => {
                                  functionContent.manageDataGatheringParams(
                                    "",
                                    {
                                      isDeleteEnable: true,
                                      parameters: {
                                        injured_person_id:
                                          el?.injured_person_id,
                                      },
                                      url: "/listira/deleteinjuredpersondetails",
                                    },
                                    false,
                                    "deleteParams"
                                  );
                                })
                              }
                              className="clide-v2-icon-btn mr-2"
                            >
                              <RoundDelete />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )
              )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default DetailStep2;
