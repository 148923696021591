import React, { Component } from "react";
import { Button, Row, Col, Modal, Tab, Nav } from "react-bootstrap";
import { Calendar, Mic, MicOff, PlusCircle, XCircle } from "react-feather";
import DatePicker from "react-datepicker";
import Url from "app/shared/constants/Url";
import ServiceList from "./ServiceList";
import ProjectList from "./ProjectList";
import { requiredMessage } from "app/shared/constants/Messages";
import { ReactMic } from "react-mic";
import moment from "moment";
import CommonDropdown from "./CommonDropdown";
import TimePicker from "react-times";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import axios from "axios";
import VoiceRecorder from "app/shared/components/VoiceRecorder/VoiceRecorder";
import { withRouter } from "react-router-dom";
import SubmitImage from "../../../../assets/images/submit.png";
import AudioPlayer from "react-h5-audio-player";

import LowSpace from "app/shared/components/Popups/LowSpace";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";

import {
  convertDateToUTC,
  convertDateFromTimezoneIST,
} from "app/shared/utility/tzUtilFunction";

import { isIntegerNumber } from "app/shared/utility/formHelper";
const qs = require("qs");

const DEPT_URL = Url.departmentList;
const CONT_URL = Url.contractorList;
const ZONE_URL = Url.zoneList;
const ACT_URL = Url.activitylist;
const hasNumber = /\d/;
class TrainingReport extends Component {
  constructor(props) {
    super(props);

    let date = new Date();
    let hours = new Date();
    hours = hours.setHours(hours.getHours() + 1);

    this.state = {
      formHideField: this.props.hideFieldList,
      reportType: this.props.reportType,
      serviceList: [],
      projectList: [],

      activityList: [],
      typeList: [{ type_id: 2, actvity: "TBT" }],

      observationText: "",
      evidenceFileList: [],
      evidenceUrlList: [],
      selectedServiceId: this.props.isFromAction ? this.props.formActionData?.project.service_id : null,
      selectedProjectId: this.props.isFromAction ? this.props.formActionData?.project.id : null,
      activeModule: 1,
      selectedActivity: this.props.isFromAction ? this.props.formActionData?.activity.id : null,
      clearDropZone: false,
      selectedSeverity: null,
      selectedTargetDate: new Date(),
      maxDatepickerDate: new Date(),
      minDatepickerDate: new Date(),
      isMaxPersonValidate: false,
      maxPersonValidationMessage: "",
      validDateField: "",
      selectedType: this.props.isFromAction ? "1" : null,
      selectedFaculty: null,
      selectedConductedStartDate: new Date(),
      selectedConductedEndDate: hours,
      topic: "",
      noOfAtendees: "",
      employee_attendees_count: null,
      contractor_attendees_count: null,
      employee_attendees_countError: false,
      contractor_attendees_countError: false,
      userData: JSON.parse(localStorage.getItem("userData")),
      lat: 0,
      long: 0,
      observationError: false,
      observationTopic: false,
      serviceError: false,
      projectError: false,
      projectError: false,

      typeActError: false,
      activityError: false,
      typeError: false,
      isMaxPersonValidateError: false,
      noOfAtendeesError: false,
      showRecorderModal: false,
      recordAudio: false,
      observationAudio: "",
      observationURL: "",
      recordingFor: "observation",

      sucesssPopup: false,
      reportNumber: 0,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
      showContractorDropdown: true,
      conductedFor: null,
      conductedForError: false,
      trainingType: null,
      trainingTypeError: false,
      schedule: null,
      isSheduleChecked: false,
      selectedFile: "",
      currentDate: new Date(),
      trainingAssignRights: {},
      conduct: [],
      male_attendees_count: "",
      female_attendees_count: "",
      userData: JSON.parse(localStorage.getItem("userData")),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hideFieldList !== prevState.formHideField) {
      return nextProps.hideFieldList;
    }
    return null;
  }

  _closeSuccessPopup = async () => {
    await this.setState({ sucesssPopup: false });
    await this._clearForm();
    this.props.close();
    this.props.history.push("/");
  };

  _continueReporting = async () => {
    await this.setState({ sucesssPopup: false });
    await this._continueReportingClearForm();
    await this._fetchServiceList();
  };

  _getLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState((prevState) => {
          let { lat, long } = prevState;
          lat = position.coords.latitude;
          long = position.coords.longitude;
          return { lat, long };
        });
      });
    }
  };

  componentDidMount = async () => {
    await this._fetchServiceList();
    await this._getLocation();
    this.props.setClick(this._submitTraining);
    if (this.state.userData.designation_flag == 1 || this.state.userData.designation_flag == 5) {
      this.setState({
        typeList: [
          { type_id: 1, actvity: "Training" },
          { type_id: 2, actvity: "TBT" },
        ],
      });
    }
     //integrating the condition to detect is Training & TBT is Enabled or Not 1/10/2024
    //  const list = [];
    //  this.state.userData.module.forEach((el) => {

    //      if(el?.module_name === "tool_box_talk" && el?.status){
    //        list.push({ type_id: 2, actvity: "TBT" })
    //      } else if(el?.module_name === "training" && el?.status){
    //        list.push({ type_id: 1, actvity: "Training" })
    //      }
    //  })
    //  if(list.length){
    //   this.setState({
    //     typeList:list
    //   });
    //  }
    
  };
  _fetchOptionList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .all([
        axios.post(ACT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
      ])
      .then(
        axios.spread(async (activity) => {
          await this.setState((prevState) => {
            let { activityList, dataLoading } = prevState;

            activityList =
              activity.data.status === 200 ? activity.data?.data : [];

            dataLoading = false;

            return {
              activityList,
              dataLoading,
            };
          });
        })
      )
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _fetchServiceList = async () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.serviceList);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { serviceList } = prevState;
              serviceList = response.data.data;
              return { serviceList };
            });
            if (this.state.userData.designation_flag === 4) {
              this.setState(
                {
                  selectedServiceId: response.data.data[0]["service_id"],
                },
                () =>
                  void this._fetchProjectList(
                    response.data.data[0]["service_id"]
                  )
              );
            }
            this._setDataLoading(false);
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _serviceChangeHandle = async (serviceId) => {
    await this.setState((prevState) => {
      let { selectedServiceId, serviceError } = prevState;
      selectedServiceId = serviceId;
      serviceError = serviceId ? false : true;
      return { selectedServiceId, serviceError };
    });
    await this._resetDropdwon();
    this._fetchProjectList(serviceId);
  };

  _resetDropdwon = async (projectReset = true) => {
    await this.setState((prevState) => {
      let { selectedProjectId, selectedActivity, projectList, activityList } =
        prevState;

      if (projectReset) {
        selectedProjectId = null;
        projectList = [];
      }

      selectedActivity = null;
      activityList = [];

      return {
        selectedProjectId,
        selectedActivity,
        projectList,
        activityList,
      };
    });
  };

  _fetchProjectList = async (serviceId) => {
    let requestData = {
      service_id: serviceId,
      module_name: "training",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { projectList } = prevState;
              projectList = response.data.data;
              return { projectList };
            });
            if (this.state.userData.designation_flag === 4) {
              const { project_id, contractor_flag, zone_location_flag } =
                response.data.data[0];

              this.setState(
                {
                  selectedProjectId: project_id,
                  selectedContractorId: contractor_flag,
                  // selectedZoneId: zone_location_flag,
                  // selectedLocationId: zone_location_flag
                },
                async () => {
                  await this._fetchOptionList();
                  this._fetchTrainingAssignRights();
                }
              );
            }
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _projectChangeHandle = async (projectId) => {
    await this._resetDropdwon(false);

    await this.setState((prevState) => {
      let {
        selectedProjectId,
        projectError,
        showContractorDropdown,
        projectList,
      } = prevState;

      // check contractor flag
      let index = projectList.findIndex((item) => projectId == item.project_id);
      const isContractor = projectList[index].contractor_flag;
      showContractorDropdown = isContractor == 0 ? false : true;
      selectedProjectId = projectId;
      projectError = projectId ? false : true;

      return { selectedProjectId, projectError, showContractorDropdown };
    });
    await this._fetchOptionList();
    this._fetchTrainingAssignRights();
  };

  _fetchTrainingAssignRights = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("springToken");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      service_id: this.state.selectedServiceId,
      project_id: this.state.selectedProjectId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(
        Url.training.assignTrainingUser,
        qs.stringify(requestData),
        ContentType
      )
      .then(async (response) => {
        if (response.data.status == 200) {
          this.setState({
            dataLoading: false,
            trainingAssignRights: response.data.data.training_rights_user,
          });

          if (this.state.trainingAssignRights.trainingrightsId) {
            this.setState({
              typeList: [
                { type_id: 1, actvity: "Training" },
                { type_id: 2, actvity: "TBT" },
              ],
              // selectedType: 1,
            });
          } else {
            this.setState({
              typeList: [{ type_id: 2, actvity: "TBT" }],
              // selectedType: 2,
            });
          }
        }
      })
      .catch(async (error) => {
        await this.setState({
          dataLoading: false,
        });
      });
  };

  _activityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedActivity, activityError } = prevState;
      selectedActivity = id;
      return { selectedActivity, activityError };
    });
  };

  _typeChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedType, typeError, conduct } = prevState;
      selectedType = id;
      typeError = id ? false : true;
      conduct = [];
      console.log(selectedType);
      return { selectedType, typeError, conduct };
    });
  };

  _contractorChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedContractorId, contractorError } = prevState;
      selectedContractorId = id;
      contractorError = id ? false : true;
      return { selectedContractorId, contractorError };
    });
  };

  _severityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSeverity, severityError } = prevState;
      selectedSeverity = id;
      severityError = id ? false : true;
      return { selectedSeverity, severityError };
    });
  };

  _typeactChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedTypeAct, typeActError } = prevState;
      selectedTypeAct = id;
      typeActError = id ? false : true;
      return { selectedTypeAct, typeActError };
    });
  };

  _evidenceFileHandle = async (event) => {
    var evidence_file = Array.from(event.target.files);
    event.target.value = "";

    const lowStorage = this.checkLowStorage();

    lowStorage
      .then(async (response) => {
        const { status, msg } = response.data;

        if (status == 200) {
          var tempUrl = [];
          var filePromise = new Promise((resolve, reject) => {
            evidence_file.forEach((item, index) => {
              let temVar;

              let reader = new FileReader();
              reader.readAsDataURL(item);
              reader.onloadend = () => {
                tempUrl.push(reader.result);
                if (index === evidence_file.length - 1) {
                  return resolve(1);
                }
              };
            });
          });

          filePromise.then(async () => {
            await this.setState((prevState) => {
              let { evidenceFileList, evidenceUrlList } = prevState;
              const tempArr = evidence_file.map((item) => item);
              evidenceFileList = [...evidenceFileList, ...tempArr];
              evidenceUrlList = [...evidenceUrlList, ...tempUrl];
              return { evidenceFileList, evidenceUrlList };
            });
          });
        }
        await this.setState({
          lowStorageStatus: status,
          lowStorageMessage: msg,
          showLowStoragePopup: status == 200 ? false : true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  _handleChange = async (
    item = null,
    type = "observation",
    callback = () => {},
    isCallBackEnabled = false
  ) => {
    await this.setState(
      (prevState) => {
        let {
          selectedConductedStartDate,
          selectedConductedEndDate,
          selectedType,
          conduct,
        } = prevState;
        switch (type) {
          case "observation":
            let { observationText, observationError, observationURL } =
              prevState;
            observationText = item;
            observationError = !observationURL && !item ? true : false;
            return { observationText, observationError };
            break;

          case "topic":
            let { topic, topicError } = prevState;
            topic = item;
            topicError = item ? false : true;
            return { topic, topicError };
            break;

          case "conducted_start_date":
            selectedConductedStartDate = item;
            var tempDate = new Date(item);
            selectedConductedEndDate = tempDate.setHours(
              tempDate.getHours() + 1
            );

            return { selectedConductedStartDate, selectedConductedEndDate };
            break;

          case "conducted_end_date":
            selectedConductedEndDate = item;
            return { selectedConductedEndDate };
            break;

          // case "no_of_atendees":
          //   let { noOfAtendees, noOfAtendeesError } = prevState;
          //   noOfAtendees = item;
          //   noOfAtendeesError = this.state.selectedType !== "1" ? item > 0 ? false : true : false;
          //   return { noOfAtendees, noOfAtendeesError };
          //   break;
          case "employee_attendees_count":
            let { employee_attendees_count, employee_attendees_countError } =
              prevState;
            employee_attendees_count = item;
            employee_attendees_countError = item > 0 ? false : true;
            return { employee_attendees_count, employee_attendees_countError };
            break;
          case "contractor_attendees_count":
            let {
              contractor_attendees_count,
              contractor_attendees_countError,
            } = prevState;
            contractor_attendees_count = item;
            contractor_attendees_countError = item > 0 ? false : true;
            return {
              contractor_attendees_count,
              contractor_attendees_countError,
            };
            break;

          case "faculty":
            let { selectedFaculty, selectedFacultyError } = prevState;
            selectedFaculty = item;
            selectedFacultyError = item ? false : true;
            return { selectedFaculty, selectedFacultyError };
            break;

          case "conducted_for":
            let { conductedFor, conductedForError } = prevState;
            conductedFor = item;
            conductedForError =
              selectedType === "1"
                ? conduct?.length === 0
                  ? true
                  : false
                : item
                ? false
                : true;
            return { conductedFor, conductedForError };
            break;
          case "male_attendees_count":
            let { male_attendees_count } = prevState;
            male_attendees_count = item;
            return { male_attendees_count };
            break;
          case "female_attendees_count":
            let { female_attendees_count } = prevState;
            female_attendees_count = item;
            return { female_attendees_count };
            break;

          case "training_type":
            let { trainingType, trainingTypeError } = prevState;
            trainingType = item;
            trainingTypeError = item ? false : true;
            return { trainingType, trainingTypeError };
            break;

          default:
            return prevState;
            break;
        }
      },
      () => {
        if (isCallBackEnabled) {
          callback();
        }
      }
    );
  };

  _handleCheck = async () => {
    this.setState({
      isSheduleChecked: !this.state.isSheduleChecked,
    });

    let time = new Date();
    let addedTime = new Date();
    let endDateTime = new Date();
    addedTime = addedTime.setDate(time.getDate() + 1);

    var tempEndDate = new Date(time);
    var tempEndDateChecked = new Date(addedTime);
    if (!this.state.isSheduleChecked) {
      this.setState({
        selectedConductedStartDate: addedTime,
        selectedConductedEndDate: tempEndDateChecked.setHours(
          tempEndDateChecked.getHours() + 1
        ),
      });
    } else {
      this.setState({
        selectedConductedStartDate: new Date(),
        selectedConductedEndDate: tempEndDate.setHours(
          tempEndDate.getHours() + 1
        ),
      });
    }
  };

  _removePreviewFile = async (index = 0) => {
    await this.setState((prevState) => {
      let { evidenceFileList, evidenceUrlList } = prevState;
      evidenceFileList.splice(index, 1);
      evidenceUrlList.splice(index, 1);
      return { evidenceFileList, evidenceUrlList };
    });
  };

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup: false });
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.observationError =
        tempObj.observationText || tempObj.observationURL ? false : true;

      prevState.serviceError = tempObj.selectedServiceId ? false : true;

      prevState.projectError = tempObj.selectedProjectId ? false : true;

      prevState.typeError = tempObj.selectedType ? false : true;
      prevState.isMaxPersonValidateError = tempObj.isMaxPersonValidate
        ? true
        : false;

      prevState.topicError = tempObj.topic ? false : true;
      
        prevState.selectedFacultyError = tempObj.selectedFaculty ? false : true;
      

      // prevState.noOfAtendeesError = tempObj?.selectedType !== "1" ? tempObj.noOfAtendees > 0 ? false : true : false;

      prevState.employee_attendees_countError =
         this.state.conduct.includes("Employee")
          ? tempObj.employee_attendees_count > 0
            ? false
            : true
          : false;
      prevState.contractor_attendees_countError =
        
        this.state.conduct.includes("Contractor")
          ? tempObj.contractor_attendees_count > 0
            ? false
            : true
          : false;
      prevState.conductedForError =
        true
          ? tempObj?.conduct?.length === 0
            ? true
            : false
          : tempObj.conductedFor
          ? false
          : true;

      if (prevState.selectedType === "1") {
        prevState.trainingTypeError = tempObj.trainingType ? false : true;
      }

      return prevState;
    });
  };

  _submitTraining = async () => {
    await this._formValidation();
    // Checking form have any Error
    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          console.log(this.state[propertyName]);
          formErrorStatus = true;
          console.log("erroe");
          return;
        } else {
          formErrorStatus = false;
        }
        console.log(formErrorStatus);
      }
    }

    // If form not have error then submit form
    if (!formErrorStatus && this.state.isMaxPersonValidate === false) {
      // this.props.parentStateSetter(({
      //   apiLoading:true
      // }));
      const userId = localStorage.getItem("userId");
      const token = localStorage.getItem("springToken");
      this.setState({
        activeModule: this.state.selectedType,
      });
      await this.setState({ dataLoading: true });

      this._setDataLoading(true);

      let soiData = new FormData();
      soiData.append("auth_key", token);
      soiData.append("user_id", userId);
      soiData.append("service_id", this.state.selectedServiceId || 0);
      soiData.append("project_id", this.state.selectedProjectId || 0);

      soiData.append("activity", this.state.selectedActivity || 0);
      soiData.append("what_all_covered", this.state.observationText || "");
      soiData.append(
        "conducted_for",
        this.state.conduct?.length > 1 ? "Employee,Contractor" : this.state.conduct.join()
      );
      soiData.append(
        "male_attendees_count",
        this.state.male_attendees_count || 0
      );
      soiData.append(
        "female_attendees_count",
        this.state.female_attendees_count || 0
      );
      soiData.append("training_type", this.state.trainingType || "");
      soiData.append("topic", this.state.topic || "");
      // if (this.state.selectedType !== "1") {
      //   soiData.append("atendees_count", this.state.noOfAtendees || 0);
      // }
      soiData.append("type", this.state.selectedType || 0);
      soiData.append("faculty", this.state.selectedFaculty || "");

      var conductedStartDate = convertDateFromTimezoneIST(
        this.state.selectedConductedStartDate
      );
      var conductedEndDate = convertDateFromTimezoneIST(
        this.state.selectedConductedEndDate
      );

      soiData.append("conducted_start_date", conductedStartDate || "");
      soiData.append(
        "conducted_end_date",
        this.state.selectedType === "1" ? conductedEndDate || "" : ""
      );

      soiData.append("lat", this.state.lat);
      soiData.append("lang", this.state.long);
      soiData.append("what_all_covered_record", this.state.observationAudio);
      // soiData.append("schedule", this.state.selectedType == 1 ? 0 : 1);
      if (this.state.isSheduleChecked) {
        soiData.append("schedule", 0);
      } else {
        soiData.append("schedule", 1);
      }
      
        soiData.append(
          "employee_attendees_count",
          this.state.employee_attendees_count || 0
        );
        soiData.append(
          "contractor_attendees_count",
          this.state.contractor_attendees_count || 0
        );
      
      // Evidence File

      soiData.append("file", this.state.selectedFile || "");
      soiData.append("file_status", this.state.selectedFile ? 1 : 0);

      var ContentType = {
        "Content-Type": "multipart/form-data",
      };

      let URL = Url.training.trainingReport;

      await axios
        .post(URL, soiData, ContentType)
        .then(async (response) => {
          this._setDataLoading(false);
          this.setState({
            clearDropZone: true,
          });
          if (response.data.status === 200) {
            await this._continueReportingClearForm();
            console.log(this.state.topic);
            console.log(this.state.selectedType);
            console.log(this.state.noOfAtendees);
            await this.setState({
              reportNumber: response.data.report_number,
              sucesssPopup: true,
            });
          } else {
            await this._continueReportingClearForm();
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
          console.log(error);
        })
        // .finally(() =>{
        //   this.props.parentStateSetter(({
        //     apiLoading:false
        //   }));
        // })
    }
  };

  _showRecorder = async (flag = false, type = "observation") => {
    if (flag) {
      const lowStorage = this.checkLowStorage();
      lowStorage
        .then(async (response) => {
          const { status, msg } = response.data;

          if (status == 200) {
            await this.setState((prevState) => {
              let { showRecorderModal, recordingFor } = prevState;
              showRecorderModal = flag;
              recordingFor = type;
              return { showRecorderModal, recordingFor };
            });
          }
          await this.setState({
            lowStorageStatus: status,
            lowStorageMessage: msg,
            showLowStoragePopup: status == 200 ? false : true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      await this.setState((prevState) => {
        let { showRecorderModal, recordingFor } = prevState;
        showRecorderModal = flag;
        recordingFor = type;
        return { showRecorderModal, recordingFor };
      });
    }
  };

  startRecording = async () => {
    await this.setState({ recordAudio: true });
  };

  stopRecording = async () => {
    await this.setState({ recordAudio: false });
  };

  onStop = async (recordedBlob) => {
    let promise = new Promise((resolve, error) => {
      let reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    promise.then(async (response) => {
      await this.setState((prevState) => {
        let {
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
        } = prevState;
        if (this.state.recordingFor === "control_measure") {
          controlMeasureAudio = recordedBlob.blob;
          controlMeasureURL = response;
        }
        if (this.state.recordingFor === "observation") {
          observationAudio = recordedBlob.blob;
          observationURL = response;

          console.log(observationURL, observationAudio);
        }
        return {
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
        };
      });
    });
  };

  _setDataLoading = async (status) => {
    await this.setState((prevState) => {
      let { dataLoading } = prevState;
      dataLoading = status;
      return { dataLoading };
    });
  };

  _removeAudioFile = async (type) => {
    await this.setState((prevState) => {
      if (type === "observation") {
        prevState.observationAudio = "";
        prevState.observationURL = "";
        return prevState;
      }

      if (type === "control_measure") {
        prevState.controlMeasureAudio = "";
        prevState.controlMeasureURL = "";
        return prevState;
      }
    });
  };

  _onTimeChange = async (options) => {
    const { hour, minute, meridiem } = options;

    const time = `${hour}:${minute}:00`;
    const formattedTime = moment(time, "HH:mm:").format("hh:mm");

    await this.setState({
      selectedIncidentTime: formattedTime,
      selectedTimeMeridiem: meridiem,
    });
  };

  _reportType = () => {
    if (this.state.reportType == "nm") {
      return "NM";
    } else if (this.state.reportType == "ira") {
      return "IRA";
    } else {
      return "UA/UC";
    }
  };

  _clearForm = async () => {
    await this.setState((prevState) => {
      prevState.projectList = [];
      prevState.departmentList = [];
      prevState.contractorList = [];
      prevState.zoneList = [];
      prevState.locationList = [];
      prevState.activityList = [];
      prevState.selectedType = null;
      prevState.observationText = "";
      prevState.evidenceFileList = [];
      prevState.evidenceUrlList = [];
      prevState.selectedServiceId =
        this.state.userData.designation_flag === 4
          ? prevState.selectedServiceId
          : null;
      prevState.selectedProjectId =
        this.state.userData.designation_flag === 4
          ? prevState.selectedProjectId
          : null;
      prevState.selectedDepartmentId = null;
      prevState.selectedContractorId = null;
      prevState.selectedZoneId = null;
      prevState.selectedLocationId = null;
      prevState.selectedTypeAct = null;
      prevState.selectedActivity = null;
      prevState.conduct = [];
      prevState.male_attendees_count = "";
      prevState.female_attendees_count = "";
      prevState.selectedTargetDate = new Date();
      prevState.soiFormHidingField = {};
      prevState.nmFormHidingField = {};
      prevState.iraFormHidingField = {};
      prevState.isMaxPersonValidate = false;
      prevState.maxPersonValidationMessage = "";
      prevState.validDateField = "";
      prevState.serviceError = false;
      prevState.projectError = false;
      prevState.employee_attendees_count = 0;
      prevState.contractor_attendees_count = 0;
      prevState.employee_attendees_countError = false;
      prevState.contractor_attendees_countError = false;
      prevState.zoneError = false;
      prevState.departmentError = false;
      prevState.contractorError = false;
      prevState.locationError = false;

      prevState.typeActError = false;
      // prevState.activityError = false;
      prevState.typeError = false;
      prevState.isMaxPersonValidateError = false;
      prevState.observationError = false;

      prevState.topicError = false;
      prevState.selectedFacultyError = false;
      prevState.noOfAtendeesError = false;
      prevState.conduct = [];
      prevState.topic = "";
      prevState.selectedType = null;
      prevState.selectedFaculty = null;
      prevState.noOfAtendees = "";
      prevState.selectedConductedStartDate = new Date();
      prevState.selectedConductedEndDate = new Date();

      prevState.showRecorderModal = false;
      prevState.recordAudio = false;
      prevState.observationAudio = "";
      prevState.observationURL = "";
      prevState.recordingFor = "observation";

      return prevState;
    });
  };

  _continueReportingClearForm = async () => {
    await this.setState((prevState) => {
      prevState.observationText = "";
      prevState.evidenceFileList = [];
      prevState.evidenceUrlList = [];
      prevState.selectedServiceId = null;
      prevState.selectedProjectId = null;
      prevState.conductedFor = null;
      prevState.selectedDepartmentId = null;
      prevState.selectedContractorId = null;
      prevState.selectedZoneId = null;
      prevState.selectedLocationId = null;
      prevState.clearDropZone = false;
      prevState.selectedFile = "";
      prevState.selectedType = 3;
      prevState.selectedActivity = null;
      prevState.isMaxPersonValidate = false;
      prevState.maxPersonValidationMessage = "";
      prevState.validDateField = "";
      prevState.topic = "";
      prevState.selectedFaculty = null;
      prevState.noOfAtendees = "";
      prevState.selectedConductedStartDate = new Date();
      prevState.selectedConductedEndDate = new Date();
      prevState.male_attendees_count = null;
      prevState.employee_attendees_count = null;
      prevState.contractor_attendees_count = null;
      prevState.trainingType = null;
      prevState.isSheduleChecked = false;
      
      prevState.conduct = [];
      prevState.female_attendees_count = null;
      prevState.soiFormHidingField = {};
      prevState.nmFormHidingField = {};

      prevState.serviceError = false;
      prevState.projectError = false;

      prevState.zoneError = false;
      prevState.departmentError = false;
      prevState.contractorError = false;
      prevState.locationError = false;

      prevState.observationError = false;

      prevState.topicError = false;
      prevState.typeError = false;
      prevState.selectedFacultyError = false;
      prevState.noOfAtendeesError = false;

      prevState.showRecorderModal = false;
      prevState.recordAudio = false;
      prevState.observationAudio = "";
      prevState.observationURL = "";
      prevState.recordingFor = "observation";

      // clearing dropdown values
      prevState.serviceList = [];
      prevState.projectList = [];
      prevState.categoryList = [];
      prevState.departmentList = [];
      prevState.contractorList = [];
      prevState.zoneList = [];
      prevState.locationList = [];
      prevState.activityList = [];
      return prevState;
    });
  };

  _addPersonName = async () => {
    await this.setState((prevState) => {
      let { personName, personNameList } = prevState;
      personNameList = [...personNameList, personName];
      personName = "";
      return { personName, personNameList };
    });
  };

  _removePersonName = async (position) => {
    await this.setState((prevState) => {
      let { personNameList } = prevState;
      personNameList.splice(position, 1);
      return { personNameList };
    });
  };

  _uploadFile = async (file) => {
    console.log(file);
    this.setState({
      selectedFile: file,
    });
  };

  manageTrainingConductor = (val, isDelete, callback = {}, event) => {
    const temp = [...this.state.conduct];

    if (isDelete) {
      const itemIndex = temp.indexOf(val);
      this._handleChange(
        null,
        temp[itemIndex] === "Employee"
          ? "employee_attendees_count"
          : "contractor_attendees_count"
      );
      temp.splice(itemIndex, 1);
    } else {
      temp.push(val);
    }
    this.setState(
      { conduct: temp, conductedForError: temp.length === 0 },
      callback(event, this.state, temp)
    );
  };

  validateMaxPersonValue = (
    e,
    state,
    conduct,
    isRecurssionType = false,
    recurssionKey = "",
    recessionValue = 0
  ) => {
    const { name, value } = e?.target ?? {};
    const {
      contractor_attendees_count,
      employee_attendees_count,
      female_attendees_count,
      male_attendees_count,
    } = state;
    if (["Employee", "Contractor"].includes(value)) {
      if (conduct.length === 1) {
        const count =
          parseInt(contractor_attendees_count ?? 0) +
          parseInt(employee_attendees_count ?? 0);
        const key =
          conduct[0] === "Employee"
            ? "employee_attendees_count"
            : "contractor_attendees_count";
        this._handleChange(count === 0 ? null : count, key);
      } else if (conduct.length === 2) {
        this._handleChange(
          employee_attendees_count !== null || 0
            ? employee_attendees_count
            : null,
          "employee_attendees_count"
        );
        this._handleChange(
          contractor_attendees_count !== null || 0
            ? contractor_attendees_count
            : null,
          "contractor_attendees_count"
        );
      }
    }
    if (
      ["male_attendees_count", "female_attendees_count"].includes(name) ||
      isRecurssionType
    ) {
      const validAmount =
        parseInt(  contractor_attendees_count ?? 0) +
        parseInt(employee_attendees_count ?? 0);
      const isFemaleField = isRecurssionType
        ? recurssionKey
        : name === "female_attendees_count";
      const currentAmount = isFemaleField
        ? parseInt(isRecurssionType ? recessionValue : value) +
          parseInt(male_attendees_count ?? 0)
        : parseInt(isRecurssionType ? recessionValue : value) +
          parseInt(female_attendees_count ?? 0);
      const isFieldInvalid = currentAmount !== validAmount;
      this.setState({
        isMaxPersonValidate: isFieldInvalid,
        validDateField: isFieldInvalid
          ? isRecurssionType
            ? "female_attendees_count"
            : name
          : "",
        maxPersonValidationMessage: isFieldInvalid
          ? `${
              isFemaleField ? "Female Attendees" : "Male Attendees"
            } value must be equal  to  Employee Attendees & Contractor Attendees Total Count `
          : "",
      });
    }
  };

  render() {
    const isOberserver = this.state.userData.designation_flag === 4;
    return (
      <>
        {/* Data Loading  */}
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        <div className="rd-content">
          <div className="rd-left-content">
            <div className="rd-box">
              <Row>
                <Col sm={6}>
                  {/* <div className="form-group">
                    <div className="file cursor-pointer file-input">
                      Upload Evidence
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => this._evidenceFileHandle(event)}
                        multiple
                        accept="image/*,video/*"
                      />
                    </div>
                    <div className="images-section mt-2">
                      {!!this.state.evidenceUrlList.length &&
                        this.state.evidenceUrlList.map((item, index) => (
                          <div
                            className="image-preview"
                            key={`soi-evidence-image-${index}`}
                          >
                            <div className="preview-box">
                              <img src={item} alt="evidence_image" />
                              <Button
                                variant="outline-primary"
                                className="close-btn"
                                onClick={() => this._removePreviewFile(index)}
                              >
                                X
                              </Button>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div> */}
                  <div className="form-group">
                    {/* <label className="form-label">Image/Video</label> */}
                    <div>
                      <MyDropzone
                        type="5"
                        uploadFile={this._uploadFile}
                        disabledPlaceHolder
                        clearDropZone={this.state.clearDropZone}
                      />
                    </div>
                  </div>

                  <div>
                    <div
                      className={`slds-form-element ${
                        this.state.topicError ? "slds-has-error" : ""
                      }`}
                    >
                      <label className="slds-form-element__label">
                        Topic
                        <abbr class="slds-required" title="required">
                          *{" "}
                        </abbr>
                      </label>
                      <div className="slds-form-element__control">
                        <input
                          className="slds-input"
                          value={this.state.topic}
                          onChange={(event) =>
                            this._handleChange(event.target.value, "topic")
                          }
                          placeholder={"Write Topic of Training/TBT"}
                        />
                      </div>
                      {!!this.state.topicError && (
                        <div class="slds-form-element__help">
                          {requiredMessage}
                        </div>
                      )}
                    </div>
                    <div
                      className={`slds-form-element ${
                        this.state.observationError ? "slds-has-error" : ""
                      }`}
                    >
                      <div className="with-mic">
                        <label className="slds-form-element__label">
                          What all covered
                          <abbr class="slds-required" title="required">
                            *{" "}
                          </abbr>
                        </label>
                      </div>
                      {this.state.observationURL ? (
                        <div className="audioPreviewer mt-2">
                          <AudioPlayer
                            src={this.state.observationURL}
                            showJumpControls={true}
                            layout="horizontal-reverse"
                            customVolumeControls={[]}
                            customAdditionalControls={[]}
                            style={{
                              width: "600px",
                            }}
                          />
                          <Button
                            variant="outline-primary"
                            onClick={() => this._removeAudioFile("observation")}
                          >
                            X
                          </Button>
                        </div>
                      ) : null}
                      <div className="slds-form-element__control">
                        <textarea
                          className="slds-textarea"
                          onChange={(event) =>
                            this._handleChange(
                              event.target.value,
                              "observation"
                            )
                          }
                          value={this.state.observationText}
                          placeholder="Write what all covered description"
                        ></textarea>
                      </div>
                      {!!this.state.observationError && (
                        <div class="slds-form-element__help" id="error-01">
                          {requiredMessage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    {/* <div className="rd-box-head">
                      <h3>Service & Plant/Project</h3>
                    </div> */}
                    {!isOberserver && !this.props.isFromAction ? (
                      <Row>
                        <Col>
                          <ServiceList
                            optionList={this.state.serviceList}
                            onChangeHandle={this._serviceChangeHandle}
                            value={this.state.selectedServiceId}
                            hasError={this.state.serviceError}
                          />
                        </Col>
                        <Col>
                          <ProjectList
                            optionList={this.state.projectList}
                            onChangeHandle={this._projectChangeHandle}
                            value={this.state.selectedProjectId}
                            hasError={this.state.projectError}
                          />
                        </Col>
                      </Row>
                    ) : null}
                    {/* <div className="rd-box-head">
                      <h3>Select Details</h3>
                    </div> */}
                    {!this.props.isFromAction &&
                      <Row>
                        <Col sm={6}>
                          <CommonDropdown
                            title="Type"
                            optionList={this.state.typeList}
                            onChangeHandle={this._typeChangeHandle}
                            value={this.state.selectedType ?? null}
                            hasError={this.state.typeError}
                          />
                        </Col>
                        <Col sm={6}>
                          <CommonDropdown
                            title="Activity"
                            optionList={this.state.activityList}
                            onChangeHandle={this._activityChangeHandle}
                            value={this.state.selectedActivity}
                          />
                        </Col>
                      </Row>
                  }
                  </div>
                </Col>
                <Col sm={6}>
                  <div>
                    <Row>
                      <Col
                        sm={12}
                        className={`slds-form-element ${
                          this.state.conductedForError ? "slds-has-error" : ""
                        }`}
                      >
                        <div className="rd-box-head">
                          <label className="slds-form-element__label font-18">
                            Conducted For
                          </label>
                        </div>
                        <div>
                          {/* {this.state.selectedType === "1" ? (
                            <div className="d-flex">
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    name="internal"
                                    type="checkbox"
                                    id="checkbox-unique-id-160"
                                    value="Employee"
                                    checked={this.state.conduct.includes(
                                      "Employee"
                                    )}
                                    onChange={(event) => {
                                      this.manageTrainingConductor(
                                        "Employee",
                                        this.state.conduct.includes("Employee"),
                                        this.validateMaxPersonValue,
                                        event
                                      );
                                    }}
                                  />
                                  <label
                                    className="slds-checkbox__label"
                                    for="checkbox-unique-id-160"
                                  >
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-form-element__label">
                                      Employee
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    name="internal"
                                    type="checkbox"
                                    id="checkbox-unique-id-161"
                                    value="Contractor"
                                    checked={this.state.conduct.includes(
                                      "Contractor"
                                    )}
                                    onChange={(event) => {
                                      this.manageTrainingConductor(
                                        "Contractor",
                                        this.state.conduct.includes(
                                          "Contractor"
                                        ),
                                        this.validateMaxPersonValue,
                                        event
                                      );
                                    }}
                                  />
                                  <label
                                    className="slds-checkbox__label"
                                    for="checkbox-unique-id-161"
                                  >
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-form-element__label">
                                      Contractor
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex">
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    name="internal"
                                    type="checkbox"
                                    id="checkbox-unique-id-160"
                                    value="Employee"
                                    checked={
                                      this.state.conductedFor == "Employee"
                                    }
                                    onChange={(event) =>
                                      this._handleChange(
                                        event.target.value,
                                        "conducted_for"
                                      )
                                    }
                                  />
                                  <label
                                    className="slds-checkbox__label"
                                    for="checkbox-unique-id-160"
                                  >
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-form-element__label">
                                      Employee
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    name="internal"
                                    type="checkbox"
                                    id="checkbox-unique-id-161"
                                    value="Contractor"
                                    checked={
                                      this.state.conductedFor == "Contractor"
                                    }
                                    onChange={(event) =>
                                      this._handleChange(
                                        event.target.value,
                                        "conducted_for"
                                      )
                                    }
                                  />
                                  <label
                                    className="slds-checkbox__label"
                                    for="checkbox-unique-id-161"
                                  >
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-form-element__label">
                                      Contractor
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          )} */}
                          <div className="d-flex">
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    name="internal"
                                    type="checkbox"
                                    id="checkbox-unique-id-160"
                                    value="Employee"
                                    checked={this.state.conduct.includes(
                                      "Employee"
                                    )}
                                    onChange={(event) => {
                                      this.manageTrainingConductor(
                                        "Employee",
                                        this.state.conduct.includes("Employee"),
                                        this.validateMaxPersonValue,
                                        event
                                      );
                                    }}
                                  />
                                  <label
                                    className="slds-checkbox__label"
                                    for="checkbox-unique-id-160"
                                  >
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-form-element__label">
                                      Employee
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    name="internal"
                                    type="checkbox"
                                    id="checkbox-unique-id-161"
                                    value="Contractor"
                                    checked={this.state.conduct.includes(
                                      "Contractor"
                                    )}
                                    onChange={(event) => {
                                      this.manageTrainingConductor(
                                        "Contractor",
                                        this.state.conduct.includes(
                                          "Contractor"
                                        ),
                                        this.validateMaxPersonValue,
                                        event
                                      );
                                    }}
                                  />
                                  <label
                                    className="slds-checkbox__label"
                                    for="checkbox-unique-id-161"
                                  >
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-form-element__label">
                                      Contractor
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          {!!this.state.conductedForError && (
                            <div class="slds-form-element__help" id="error-01">
                              {requiredMessage}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col>
                        {this.state.selectedType === "1" ? (
                          <div
                            className={`slds-form-element ${
                              this.state.trainingTypeError
                                ? "slds-has-error"
                                : ""
                            }`}
                          >
                            <div className="rd-box-head">
                              <label className="slds-form-element__label font-18">
                                Training Type
                              </label>
                            </div>
                            <div className="d-flex  ">
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    name="internal"
                                    type="checkbox"
                                    value="Internal"
                                    id="checkbox-unique-id-155"
                                    checked={
                                      this.state.trainingType == "Internal"
                                    }
                                    onChange={(event) =>
                                      this._handleChange(
                                        event.target.value,
                                        "training_type"
                                      )
                                    }
                                  />
                                  <label
                                    className="slds-checkbox__label"
                                    for="checkbox-unique-id-155"
                                  >
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-form-element__label">
                                      Internal
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div className="slds-form-element__control">
                                <div className="slds-checkbox">
                                  <input
                                    name="external"
                                    type="checkbox"
                                    value="External"
                                    id="checkbox-unique-id-154"
                                    checked={
                                      this.state.trainingType == "External"
                                    }
                                    onChange={(event) =>
                                      this._handleChange(
                                        event.target.value,
                                        "training_type"
                                      )
                                    }
                                  />
                                  <label
                                    className="slds-checkbox__label"
                                    for="checkbox-unique-id-154"
                                  >
                                    <span className="slds-checkbox_faux"></span>
                                    <span className="slds-form-element__label">
                                      External
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {!!this.state.trainingTypeError && (
                              <div
                                class="slds-form-element__help"
                                id="error-01"
                              >
                                {requiredMessage}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </Col>

                      <Col>
                        {this.state.selectedType === "1" ? (
                          <div className="slds-form-element">
                            <div className="rd-box-head">
                              <label className="slds-form-element__label font-18">
                                Scheduled
                              </label>
                            </div>
                            <div className="slds-form-element__control">
                              <div className="slds-checkbox">
                                <input
                                  name="options"
                                  id="checkbox-unique-id-164"
                                  type="checkbox"
                                  checked={this.state.isSheduleChecked}
                                  onChange={() => {
                                    this._handleCheck();
                                  }}
                                />
                                <label
                                  className="slds-checkbox__label"
                                  for="checkbox-unique-id-164"
                                >
                                  <span className="slds-checkbox_faux"></span>
                                  <span className="slds-form-element__label">
                                    Scheduled
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <div>
                          <div className="rd-box-head">
                            <label className="slds-form-element__label font-18">
                              Conducted Date & Time
                            </label>
                          </div>
                        </div>
                      </Col>

                      {this.state.isSheduleChecked == true ? (
                        <Col sm={6}>
                          <div>
                            <label className="slds-form-element__label">
                              Start Date & Time
                            </label>
                            <div className="calender-div">
                              <DatePicker
                                selected={this.state.selectedConductedStartDate}
                                minDate={this.state.maxDatepickerDate}
                                showTimeSelect
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm:ss"
                                popperPlacement="left"
                                popperModifiers={{
                                  flip: { behavior: ["bottom"] },
                                  preventOverflow: { enabled: false },
                                  hide: { enabled: false },
                                }}
                                onChange={(jsDate) =>
                                  this._handleChange(
                                    jsDate,
                                    "conducted_start_date"
                                  )
                                }
                              />
                              <Calendar className="calender-icon" />
                            </div>
                          </div>
                        </Col>
                      ) : (
                        <Col sm={12}>
                          <div>
                            <label className="slds-form-element__label">
                              Start Date & Time
                            </label>
                            <div className="calender-div">
                              <DatePicker
                                selected={this.state.selectedConductedStartDate}
                                maxDate={this.state.minDatepickerDate}
                                showTimeSelect
                                timeFormat="HH:mm"
                                // popperPlacement="left"                                
                                // popperModifiers={{
                                //   flip: { behavior: ["bottom"] },
                                //   preventOverflow: { enabled: false },
                                //   hide: { enabled: false },
                                // }}
                                dateFormat="dd/MM/yyyy HH:mm:ss"
                                onChange={(jsDate) =>
                                  this._handleChange(
                                    jsDate,
                                    "conducted_start_date"
                                  )
                                }
                              />
                              <Calendar className="calender-icon" />
                            </div>
                          </div>
                        </Col>
                      )}

                      {this.state.selectedType === "1" ? (
                        this.state.isSheduleChecked == true ? (
                          <Col sm={6}>
                            <div>
                              <label className="slds-form-element__label">
                                End Date & Time
                              </label>
                              <div className="calender-div">
                                <DatePicker
                                  selected={this.state.selectedConductedEndDate}
                                  minDate={
                                    this.state.selectedConductedStartDate
                                  }
                                  showTimeSelect
                                  popperPlacement="left"
                                  timeFormat="HH:mm"
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  onChange={(jsDate) =>
                                    this._handleChange(
                                      jsDate,
                                      "conducted_end_date"
                                    )
                                  }
                                  
                                />
                                <Calendar className="calender-icon" />
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <Col sm={6}>
                            <div>
                              <label className="slds-form-element__label">
                                End Date & Time
                              </label>
                              <div className="calender-div">
                                <DatePicker
                                  selected={this.state.selectedConductedEndDate}
                                  minDate={
                                    this.state.selectedConductedStartDate
                                  }
                                  maxDate={this.state.currentDate}
                                  showTimeSelect
                                  timeFormat="HH:mm"
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  onChange={(jsDate) =>
                                    this._handleChange(
                                      jsDate,
                                      "conducted_end_date"
                                    )
                                  }
                                  popperPlacement="left"
                                />
                                <Calendar className="calender-icon" />
                              </div>
                            </div>
                          </Col>
                        )
                      ) : null}
                      {this.state.selectedType !== null && this.state.selectedType != "3"  ? (
                        <>
                          {this.state.conduct.includes("Employee") ? (
                            <Col sm={6}>
                              <div
                                className={`slds-form-element ${
                                  this.state.employee_attendees_countError
                                    ? "slds-has-error"
                                    : ""
                                }`}
                              >
                                <label className="slds-form-element__label">
                                  Employee Attendees{" "}
                                  <abbr class="slds-required" title="required">
                                    *{" "}
                                  </abbr>
                                </label>
                                <input
                                  className="slds-input"
                                  value={this.state.employee_attendees_count}
                                  type="text"
                                  name="employee_attendees_count"
                                  onChange={(event) => {
                                    const { value } = event?.target;
                                    if(value === "-"){
                                      this._handleChange(
                                        "",
                                        "employee_attendees_count"
                                      );
                                    }
                                    if (
                                      value === "0" ||
                                      value === "" ||
                                      isIntegerNumber(value)
                                    ) {
                                      this._handleChange(
                                        value,
                                        "employee_attendees_count"
                                      );
                                      this.validateMaxPersonValue(
                                        event,
                                        this.state,
                                        [],
                                        true,
                                        "female_attendees_count",
                                        value
                                      );
                                    }
                                  }}
                                  placeholder={"Enter Employee Attendees"}
                                />
                                {!!this.state.employee_attendees_countError &&
                                  (this.state.employee_attendees_countError <
                                  0 ? (
                                    <div
                                      class="slds-form-element__help"
                                      id="error-01"
                                    >
                                      {" "}
                                      Negative numbers are not allowed
                                    </div>
                                  ) : (
                                    <div
                                      class="slds-form-element__help"
                                      id="error-01"
                                    >
                                      {requiredMessage}
                                    </div>
                                  ))}
                              </div>
                            </Col>
                          ) : null}
                          {this.state.conduct.includes("Contractor") ? (
                            <Col sm={6}>
                              <div
                                className={`slds-form-element ${
                                  this.state.contractor_attendees_countError
                                    ? "slds-has-error"
                                    : ""
                                }`}
                              >
                                <label className="slds-form-element__label">
                                  <abbr class="slds-required" title="required">
                                    *{" "}
                                  </abbr>{" "}
                                  Contractor Attendees
                                </label>
                                <input
                                  className="slds-input"
                                  value={this.state.contractor_attendees_count}
                                  type="text"
                                  name="contractor_attendees_count"
                                  onChange={(event) => {
                                    const { value } = event?.target;
                                    if(value === "-"){
                                      this._handleChange(
                                        "",
                                        "contractor_attendees_count"
                                      );
                                    }
                                    if (
                                      value === "0" ||
                                      value === "" ||
                                      isIntegerNumber(value)
                                    ) {
                                      this._handleChange(
                                        value,
                                        "contractor_attendees_count"
                                      );
                                      this.validateMaxPersonValue(
                                        event,
                                        this.state,
                                        [],
                                        true,
                                        "female_attendees_count",
                                        value
                                      );
                                    }
                                  }}
                                  placeholder={"Enter Contractor Attendees"}
                                />
                                {!!this.state.contractor_attendees_countError &&
                                  (this.state.contractor_attendees_countError <
                                  0 ? (
                                    <div
                                      class="slds-form-element__help"
                                      id="error-01"
                                    >
                                      {" "}
                                      Negative numbers are not allowed
                                    </div>
                                  ) : (
                                    <div
                                      class="slds-form-element__help"
                                      id="error-01"
                                    >
                                      {requiredMessage}
                                    </div>
                                  ))}
                              </div>
                            </Col>
                          ) : null}{" "}
                        </>
                      ) : (
                        <Col>
                        </Col>
                      )}

                      <Col sm={6}>
                        {this.state.selectedType !== null &&  this.state.selectedType != "3" ? (
                          <div
                            className={`slds-form-element ${
                              this.state.selectedFacultyError
                                ? "slds-has-error"
                                : ""
                            }`}
                          >
                            <label className="slds-form-element__label">
                              Faculty
                              <abbr class="slds-required" title="required">
                                *{" "}
                              </abbr>
                            </label>
                            <div className="slds-form-element__control">
                              <input
                                className="slds-input"
                                value={this.state.selectedFaculty}
                                onChange={(event) => {
                                  if (!hasNumber.test(event.target.value)) {
                                    this._handleChange(
                                      event.target.value,
                                      "faculty"
                                    );
                                  }
                                }}
                                placeholder={"Enter Faculty Name"}
                              />
                            </div>
                            {!!this.state.selectedFacultyError && (
                              <div
                                class="slds-form-element__help"
                                id="error-01"
                              >
                                {requiredMessage}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </Col>
                      {this.state.selectedType !== null &&  this.state.selectedType != "3" ? (
                        <>
                          <Col sm={6}>
                            <div
                              className={`slds-form-element ${
                                this.state.validDateField ==
                                "male_attendees_count"
                                  ? "slds-has-error"
                                  : ""
                              }`}
                            >
                              <label className="slds-form-element__label">
                                Male Attendees
                              </label>
                              {/* <span className="inputErrorMsg">&nbsp;*</span> */}
                              <input
                                className="slds-input"
                                value={this.state.male_attendees_count}
                                type="text"
                                name="male_attendees_count"
                                onChange={(event) => {
                                  const { value } = event?.target;
                                  if(value === "-"){
                                    this._handleChange(
                                      "",
                                      "male_attendees_count"
                                    );
                                  }
                                  if (
                                    value === "0" ||
                                    value === "" ||
                                    isIntegerNumber(value)
                                  ) {
                                    this._handleChange(
                                      event.target.value,
                                      "male_attendees_count"
                                    );
                                    this.validateMaxPersonValue(
                                      event,
                                      this.state
                                    );
                                  }
                                }}
                                placeholder={"Enter Number of Attendees"}
                              />
                              {this.state.validDateField ==
                                "male_attendees_count" &&
                                this.state.isMaxPersonValidate && (
                                  <div
                                    class="slds-form-element__help"
                                    id="error-01"
                                  >
                                    {this.state.maxPersonValidationMessage}
                                  </div>
                                )}
                            </div>
                          </Col>
                          <Col sm={6}>
                            <div
                              className={`slds-form-element ${
                                this.state.validDateField ==
                                "female_attendees_count"
                                  ? "slds-has-error"
                                  : ""
                              }`}
                            >
                              <label className="slds-form-element__label">
                                Female Attendees
                              </label>
                              {/* <span className="inputErrorMsg">&nbsp;*</span> */}
                              <input
                                className="slds-input"
                                value={this.state.female_attendees_count}
                                type="text"
                                name="female_attendees_count"
                                onChange={(event) => {
                                  const { value } = event?.target;
                                  if(value === "-"){
                                    this._handleChange(
                                      "",
                                      "female_attendees_count"
                                    );
                                  }
                                  if (
                                    value === "0" ||
                                    value === "" ||
                                    isIntegerNumber(value)
                                  ) {
                                    this._handleChange(
                                      event.target.value,
                                      "female_attendees_count"
                                    );
                                    this.validateMaxPersonValue(
                                      event,
                                      this.state
                                    );
                                  }
                                }}
                                placeholder={"Enter Number of Attendees"}
                              />
                              {this.state.validDateField ==
                                "female_attendees_count" &&
                                this.state.isMaxPersonValidate && (
                                  <div
                                    class="slds-form-element__help"
                                    id="error-01"
                                  >
                                    {this.state.maxPersonValidationMessage}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </>
                      ) : null}
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {/* <div className="publish-btn">
          <Button
            variant="secondary"
            onClick={this.props.close}
            className="gray-btn"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={this._submitTraining}
            className="red-btn"
          >
            REPORT
          </Button>
        </div> */}

        <Modal
          show={this.state.showRecorderModal}
          dialogClassName="modal-90w"
          aria-labelledby="Voice Recording"
          onHide={() => this._showRecorder(false)}
          className="recordingModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Record</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <Button onClick={this.startRecording} className="red-btn">
                  <Mic />
                </Button>
                <Button onClick={this.stopRecording} className="gray-btn">
                  <MicOff />
                </Button>
              </div>
              <div className="obervationRecording">
                {/* <ReactMic
                  record={this.state.recordAudio}
                  className="sound-wave"
                  onStop={this.onStop}
                  //strokeColor="#000000"
                  mimeType="audio/mp3"
                  strokeColor="#000000"
                  backgroundColor="#FF4081"
                /> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showRecorderModal}
          dialogClassName="modal-90w"
          aria-labelledby="Voice Recording"
          onHide={() => this._showRecorder(false)}
          className="recordingModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="obervationRecording">
              <ReactMic
                record={this.state.recordAudio}
                className="sound-wave"
                onStop={this.onStop}
                strokeColor="#000000"
                mimeType="audio/mp3"
                // strokeColor="#000000"
                backgroundColor="#FF4081"
              />

              {/* <VoiceRecorder
                onStop={this.onStop}
                stopRecording={this.stopRecording}
              /> */}
            </div>

            <div className="modal-footer-btn">
              <Button onClick={this.startRecording} className="red-btn">
                <Mic /> Start Recording
              </Button>
              <Button onClick={this.stopRecording} className="gray-btn">
                <MicOff /> Stop Recording
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          centered
          show={this.state.sucesssPopup}
          className="recordingModal"
          onHide={this._closeSuccessPopup}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body className="text-center">
            <img
              className="submit-img"
              //src={require("assets/images/submit.png")}
              src={SubmitImage}
              alt="submit_image"
            />
            <p className="report-submit-text"> Thank you for Reporting! </p>
            <p className="report-submit-text"> You have reported </p>
            <p className="report-submit-text">
              {this.state.reportNumber || 0}
              {this.state.activeModule == 1
                ? " Trainings till today"
                : " TBTs till today"}
            </p>

            <div className="modal-footer-btn">
              <button
                type="submit"
                className="red-btn"
                onClick={this._closeSuccessPopup}
              >
                GOTO DASHBOARD
              </button>
              <button
                type="submit"
                className="red-btn"
                onClick={this._continueReporting}
              >
                CONTINUE, REPORTING
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {this.state.showLowStoragePopup ? (
          <LowSpace
            message={this.state.lowStorageMessage}
            handleLowSpace={this._handleLowSpace}
          />
        ) : null}
      </>
    );
  }
}

export default withRouter(TrainingReport);
