import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Row,
  Col,
  Container,
  Accordion,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import {
  Calendar,
  Mic,
  MicOff,
  ChevronDown,
  ChevronRight,
  Image,
} from "react-feather";
import { Redirect, withRouter } from "react-router-dom";
import { InviteUser } from "app/shared/components/Popups";
import AsyncSelect from 'react-select/async';
import Url from "app/shared/constants/Url";
import axios from "axios";
import DatePicker from "react-datepicker";
import TimePicker from "react-times";
import AudioPlayer from "react-h5-audio-player";
import dateFormat from "dateformat";
import { ReactMic } from "react-mic";
import moment from "moment";
import Lightbox from "react-awesome-lightbox";
import { Link } from "react-router-dom";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import { INCIDENT_TYPE, SEVERITY } from "app/shared/constants/GlobalArray";
import LowSpace from "app/shared/components/Popups/LowSpace";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { XCircle, PlusCircle } from "react-feather";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";
import { convertDateFromTimezone, convertDateToUTC } from "app/shared/utility/tzUtilFunction";
import ClideLoader from "app/shared/ui-kit/loader";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
//assets
import {
  RoundDelete,
  RoundEdit,
  RoundSave,
  TeamAdd,
} from "assets/Iconsv2/Iconsv2";

import { Scrollbars } from "react-custom-scrollbars";
import CountGraph from "app/views/Graphs/Graphs/CountGraph";
//seeting
import { config } from "config.js";
import { getInitials } from "app/views/Nm/container/utils/moduleHelp";
import { ImageCard,VideoCard } from "app/views/Nm/Detail/NmEditReportingDetail";
import { withTranslation } from "react-i18next";

const qs = require("qs");
const DEPT_URL = Url.departmentList;
const CONT_URL = Url.contractorList;
const ZONE_URL = Url.zoneList;
const ACT_URL = Url.activitylist;
const HZD_URL = Url.hazardList;
const BARRIER_URL = Url.barrierList;
const userId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

const ErrorMessage = <div className="invalid-feedback">Enter valid name</div>;
const ErrorBlock = ({ message }) => (
  <div className="invalid-feedback">{message || ""}</div>
);

class IraEditReportingDetail extends Component {
  constructor(props) {
    super(props);
    const {t} = this.props;
    this.state = {
      show: false,
      showUser: false,
      reportDetail: [],
      searchFiledUsers: [],
      iraId: this.props.match.params.id,
      dataLoading: true,
      accordionOpen: true,
      lightboxactive: false,
      subcommentlist: [],
      comment: 0,
      commentAdd: "",
      commentlist: [],
      allComments: false,
      redirectToReferrer: false,
      projectList: [],
      departmentList: [],
      contractorList: [],
      zoneList: [],
      locationList: [],
      incidentTypeList: INCIDENT_TYPE,
      activityList: [],
      severityList: SEVERITY,
      barrierList: [],
      hazardList: [],
      subHazardList: [],
      selectedServiceId: null,
      selectedProjectId: null,
      selectedDepartmentId: null,
      selectedContractorId: null,
      selectedZoneId: null,
      selectedLocationId: null,
      selectedTypeIncidence: null,
      selectedActivity: null,
      selectedSeverity: 0,
      selectedBarrier: null,
      selectedHazardId: null,
      selectedSubHazardId: null,
      selectedTargetDate: new Date(),
      selectedIncidentDate: new Date(),
      selectedOldDate: new Date(),
      maxDatepickerDate: new Date(),
      observationText: "",
      evidenceFileList: [],
      evidenceUrlList: [],
      serviceId: this.props.match.params.serviceId,
      projectId: this.props.match.params.projectId,
      reportDetailevidence: [],
      showRecorderModal: false,
      recordAudio: false,
      controlMeasureAudio: "",
      controlMeasureURL: "",
      observationAudio: "",
      observationURL: "",
      recordingFor: "observation",
      controlMeasureFileStatus: 0,
      observationFileStatus: 0,
      evidenceFileStatus: 0,
      isCheckLowSpace: false,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
      formSetting: [],
      selectedIncidentTime: "10:00",
      selectedTimeMeridiem: "",
      incidentPersonName: [],
      userLike: [],
      likeNumber: "",
      addedFiles: [],
      files: [],
      deletedEvidencesIds: [],
      incidentPersonNameError: false,
      injuredPersonName: "",
      injuredPersonID: "",
      // deletePersonId: null,
      incidentPersons: []
    };
    this.openInviteUser = this.openInviteUser.bind(this);
  }

  openInviteUser() {
    const showUser = !this.state.showUser;
    this.setState({
      showUser,
    });
  }

  componentDidMount() {
    this.reportDetail();
    this.UserWhoLiked();
    this._fetchProjectListFlag(this.state.serviceId);
  }

  _fetchProjectListFlag = async (serviceId) => {
    const { reportType } = this.state;
    let moduleId = 2;
    // if (reportType == "soi") {
    //   moduleId = 1;
    // } else if (reportType == "nm") {
    //   moduleId = 2;
    // } else if (reportType == "ira") {
    //   moduleId = 3;
    // }

    let requestData = {
      service_id: serviceId,
      module_name: "ira",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let {
                showContractorDropdown,
                showZoneLocationDropdown,
                projectList,
              } = prevState;

              projectList = response.data.data;

              // check contractor flag
              const isContractor = projectList.map(
                (item) => item.contractor_flag
              );
              showContractorDropdown = isContractor == 0 ? false : true;
              console.log("isContractor == " + isContractor);

              const isZoneLocation = projectList.map(
                (item) => item.zone_location_flag
              );
              showZoneLocationDropdown = isZoneLocation == 0 ? false : true;
              console.log("isZoneLocation == " + isZoneLocation);

              return {
                showContractorDropdown,
                showZoneLocationDropdown,
                projectList,
              };
            });
          }
        })
        .catch((error) => {
          this.setState({ dataLoading: false });
        });
    });
  };

  async reportDetail() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var datailUrl = Url.iraModule.iraDetails;

    await axios
      .post(
        datailUrl,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          ira_id: this.state.iraId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status == 200) {
          const oldDate = response.data.data.target_date
            ? this._momentDate(response.data.data.target_date)
            : new Date();
          await this.setState({
            reportDetail: response.data.data.basic_ira,
            formSetting: response.data.form_setting,
            selectedActivity: response.data.data.basic_ira.activity_id,
            selectedEvidence: response.data.data.basic_ira.evidence_images,
            observationURL: response.data.data.basic_ira.observation_record,
            observationText: response.data.data.basic_ira.observation,
            incidentPersons:
              response.data.data.basic_ira.incident_person_details.map((el) => {
                return {
                  ...el,
                  status: "NoChange"
                }
              }),
            selectedDepartmentId: response.data.data.basic_ira.department_id,
            selectedContractorId: response.data.data.basic_ira.contractor_id,
            selectedZoneId: response.data.data.basic_ira.zone_id,
            selectedLocationId: response.data.data.basic_ira.location_id,
            invited_user: response.data.data.basic_ira.invited_user,
            selectedSeverity: response.data.data.basic_ira.severity_number,
            
            selectedTargetDate: oldDate,
            selectedIncidentDate: this._momentDate(
              response.data.data.basic_ira.incident_date
            ),
            selectedOldDate: oldDate,
            evidenceImage: response.data.data.basic_ira.evidence_images,
            selectedIncidentTime: response.data.data.basic_ira.incident_time,
            
          });
          if (response.data.data.basic_ira.type_of_incidence == "LTI") {
            await this.setState({ selectedTypeIncidence: 1 });
          } else if (response.data.data.basic_ira.type_of_incidence == "MTC") {
            await this.setState({ selectedTypeIncidence: 2 });
          } else if (response.data.data.basic_ira.type_of_incidence == "TRA") {
            await this.setState({ selectedTypeIncidence: 3 });
          }
          else if (response.data.data.basic_ira.type_of_incidence == "FA") {
            await this.setState({ selectedTypeIncidence: 4 });
          }

          /* // Britannia v01
  //  Making this change to ...(Integrate the another incident option becuase of customization ) */
          else if (response.data.data.basic_ira.type_of_incidence == "First Aid") {
            await this.setState({ selectedTypeIncidence: 5 });
          }
          else if (response.data.data.basic_ira.type_of_incidence == "Occupational Illness") {
            await this.setState({ selectedTypeIncidence: 6 });
          }
          else if (response.data.data.basic_ira.type_of_incidence == "Notable") {
            await this.setState({ selectedTypeIncidence: 7 });
          }

          await this._fetchOptionList();
          await this.setState({
            dataLoading: false,
            // selectedTargetDate: response.data.data.target_date
          });
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }

  _redirectToDetail = () => {
    this.props.history.goBack();
    // this.setState({ redirectToReferrer: true });
  };

  _momentDate = (stringDate) => moment(stringDate, "DD-MM-YYYY").toDate();

  EditreportDetail = async () => {
    this.setState({ dataLoading: true });

    let { ...eData } = this.state;

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let targetDate = moment(this.state.selectedTargetDate).format("YYYY-MM-DD");
    let incidentDate = moment(this.state.selectedIncidentDate).format(
      "YYYY-MM-DD"
    );

    // const {t}=this.props;

    // let targetDate = convertDateToUTC(this.state.selectedTargetDate, "YYYY-MM-DD");
    // let incidentDate = convertDateToUTC(this.state.selectedIncidentDate, "YYYY-MM-DD");

    var controlMeasureURL =
      eData.controlMeasureFileStatus == 2
        ? eData.controlMeasureAudio
        : eData.controlMeasureURL;
    var observationURL =
      eData.observationFileStatus == 2
        ? eData.observationAudio
        : eData.observationURL;

    let nmData = new FormData();

    nmData.append("auth_key", token);
    nmData.append("user_id", userId);
    nmData.append("ira_id", eData.iraId);
    nmData.append("service_id", eData.serviceId);
    nmData.append("project_id", eData.projectId);
    nmData.append("activity", eData.selectedActivity);
    nmData.append("type_of_act", 0);
    nmData.append("incident_type", eData.selectedTypeIncidence);
    nmData.append("hazard", eData.selectedHazardId || 0);
    nmData.append("severity", eData.selectedSeverity);
    nmData.append("department", eData.selectedDepartmentId);
    nmData.append("contractor", eData.selectedContractorId);
    nmData.append("zone", eData.selectedZoneId);
    nmData.append("location", eData.selectedLocationId);
    nmData.append("observation", eData.observationText);
    //nmData.append("person", eData.incidentPersonName[0].person_name);
    nmData.append("target_date", targetDate);
    nmData.append("incident_time", eData.selectedIncidentTime);
    nmData.append("incident_date", incidentDate);
    // nmData.append('person', "1|hhh");

    // let tempPerson = [];
    // this.state.incidentPersonName.map((data) => {
    //   let obj = {
    //     personId: data.personId,
    //     person_name: data.person_name,
    //   }

    //   tempPerson.push(obj);
    //   //nmData.append('person', JSON.stringify(obj));
    // });

    nmData.append("person", JSON.stringify([...this.state.incidentPersons, ...this.state.searchFiledUsers]));

    // this.state.incidentPersonName.forEach(item => {
    //   nmData.append('person', item);
    //  });

    // this.state.incidentPersonName.forEach((item) => {
    //   if (item.personId === 0) {
    //     nmData.append("person", 0 +"|"+ item.person_name);
    //   } else {
    //     nmData.append("person", item.personId +"|"+ item.person_name);
    //   }
    // });

    // this.state.incidentPersonName.forEach(item => {
    //   if(item.personId === 0){
    //     nmData.append('person_id', 0);
    //     nmData.append('person_name', item.person_name);
    //   }
    //   else{
    //   nmData.append('person_id', item.personId);
    //   nmData.append('person_name', item.person_name);
    // }
    //  });

    // eData.reportDetailevidence.forEach((item) => {
    //   nmData.append("file", item);
    // });
    // if (eData.reportDetailevidence?.length === 0) {
    //   nmData.append("file", "");
    // }
    // nmData.append("observation_record", observationURL);

    // // nmData.append("observation_file_status", eData.observationFileStatus);

    // nmData.append("file_status", eData.reportDetailevidence?.length > 0 ? 1 : 0);

    nmData.append(
      "delete_evidence_ids",
      this.state.deletedEvidencesIds.toString()
    );
    console.log(this.state.files);

    this.state.files.forEach((item) => {
      nmData.append("file", item.file);
      console.log(item.file);
      // nmData.append("file_status", item.file_status);
      // nmData.append("file_id", item.file_id);
    });

    var datailUrl = Url.iraModule.iraEdit;
    let headerType = "multipart/form-data";
    await axios
      .post(datailUrl, nmData, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then(async (response) => {
        await this.setState({ dataLoading: false });
        if (response.data.status == 200) {
          AlertMessage(
            { message: this.props.t('reportEdited'), type: "success" },
            this._redirectToDetail
          );
        } else {
          AlertMessage({
            message: this.props.t('editReportFailed'),
            type: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
        AlertMessage({ message: error, type: "error" });
      });
  };

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup: false });
  };

  _fetchOptionList = async () => {
    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.serviceId,
      project_id: this.state.projectId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .all([
        // Department list
        axios.post(DEPT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Contractor List
        axios.post(CONT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Zone List
        axios.post(ZONE_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Activity List
        axios.post(ACT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Hazard List
        axios.post(HZD_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Barrier List
        axios.post(BARRIER_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
      ])
      .then(
        axios.spread(
          async (department, contractor, zone, activity, hazard, barrier) => {
            await this.setState((prevState) => {
              let {
                contractorList,
                departmentList,
                zoneList,
                hazardList,
                activityList,
                barrierList,
              } = prevState;

              departmentList =
                department.data.status === 200 ? department.data.data : [];
              contractorList =
                contractor.data.status === 200 ? contractor.data.data : [];
              zoneList = zone.data.status === 200 ? zone.data.data : [];
              hazardList = hazard.data.status === 200 ? hazard.data.data : [];
              activityList =
                activity.data.status === 200 ? activity.data.data : [];
              barrierList =
                barrier.data.status === 200 ? barrier.data.data : [];
              //  dataLoading = false;
              return {
                contractorList,
                departmentList,
                zoneList,
                hazardList,
                activityList,
                barrierList,
              };
            });
            // await this._severityChangeHandle(this.state.selectedSeverity)
            await this._activityChangeHandle(this.state.selectedActivity);
            await this._barrierChangeHandle(this.state.selectedBarrier);
            await this._hazardChangeHandle(this.state.selectedHazardId);
            await this._subHazardChangeHandle(this.state.selectedSubHazardId);
            await this._departmentChangeHandle(this.state.selectedDepartmentId);
            await this._contractorChangeHandle(this.state.selectedContractorId);
            await this._zoneChangeHandle(this.state.selectedZoneId);
            await this._locationChangeHandle(this.state.selectedLocationId);
          }
        )
      )
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _zoneChangeHandle = async (zoneId) => {
    await this.setState((prevState) => {
      let { selectedZoneId } = prevState;
      selectedZoneId = zoneId;
      //zoneError = zoneId ? false : true;
      return { selectedZoneId };
    });
    await this._fetchLocationList();
  };

  _fetchLocationList = async (zoneId) => {
    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.serviceId,
      project_id: this.state.projectId,
      zone_id: this.state.selectedZoneId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.locationList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { locationList } = prevState;
              locationList = response.data.data;
              return { locationList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _fetchSubHazardList = async () => {
    let requestData = {
      auth_key: token,
      user_id: userId,
      hazard_Id: this.state.selectedHazardId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.subhazardList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { subHazardList } = prevState;
              subHazardList = response.data.data;
              return { subHazardList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _hazardChangeHandle = async (hazardId) => {
    await this.setState((prevState) => {
      let { selectedHazardId } = prevState;
      selectedHazardId = hazardId;
      return { selectedHazardId };
    });
    await this._fetchSubHazardList();
  };

  _departmentChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedDepartmentId } = prevState;
      selectedDepartmentId = id;
      //departmentError = id ? false : true;
      return { selectedDepartmentId };
    });
  };

  _locationChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedLocationId } = prevState;
      selectedLocationId = id;
      //locationError = id ? false : true;
      return { selectedLocationId };
    });
  };

  _subHazardChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSubHazardId } = prevState;
      selectedSubHazardId = id;
      //subHazardError = id ? false : true;
      return { selectedSubHazardId };
    });
  };

  _barrierChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedBarrier } = prevState;
      selectedBarrier = id;
      return { selectedBarrier };
    });
  };

  _activityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedActivity } = prevState;
      selectedActivity = id;
      return { selectedActivity };
    });
  };

  _contractorChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedContractorId } = prevState;
      selectedContractorId = id;
      //contractorError = id ? false : true;
      return { selectedContractorId };
    });
  };

  _typeactChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedTypeIncidence } = prevState;
      selectedTypeIncidence = id;
      return { selectedTypeIncidence };
    });
  };

  _severityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSeverity } = prevState;
      selectedSeverity = id;
      return { selectedSeverity };
    });
  };

  _handleChange = async (item = null, type = "observation") => {
    await this.setState((prevState) => {
      switch (type) {
        case "observation":
          let { observationText, observationError, observationURL } = prevState;
          observationText = item;
          observationError = !observationURL && !item ? true : false;
          return { observationText, observationError };
          break;
        case "incidentpersonname":
          let { incidentPersonName, incidentPersonError } = prevState;
          incidentPersonName[0].person_name = item;
          incidentPersonError = !incidentPersonName && !item ? true : false;
          return { incidentPersonName, incidentPersonError };
          break;
        case "target_date":
          let { selectedTargetDate } = prevState;
          selectedTargetDate = item;
          return { selectedTargetDate };
          break;
        case "incident_date":
          let { selectedIncidentDate } = prevState;
          selectedIncidentDate = item;
          return { selectedIncidentDate };
          break;
        case "person_name":
          let { personName, personNameError } = prevState;
          personName = item;
          personNameError = item ? false : true;
          return { personName, personNameError };
          break;
        case "resolve_checkbox":
          let { resolveCheckbox } = prevState;
          resolveCheckbox = item ? 1 : 0;
          return { resolveCheckbox };
          break;
        default:
          return prevState;
          break;
      }
    });
  };

  _removeAudioFile = async (type) => {
    await this.setState((prevState) => {
      let {
        observationAudio,
        observationURL,
        controlMeasureAudio,
        controlMeasureURL,
        controlMeasureFileStatus,
        observationFileStatus,
      } = prevState;

      if (type === "observation") {
        observationAudio = "";
        observationURL = "";
        observationFileStatus = 1;
      }

      if (type === "control_measure") {
        controlMeasureAudio = "";
        controlMeasureURL = "";
        controlMeasureFileStatus = 1;
      }
      return {
        observationAudio,
        observationURL,
        controlMeasureAudio,
        controlMeasureURL,
        controlMeasureFileStatus,
        observationFileStatus,
      };
    });
  };

  removeInviteUser = async (selectedUserId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.removeInviteUser,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          selected_user_id: selectedUserId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: this.props.t('userRemoved'),
            type: "success",
          });
          this.reportDetail();
        } else {
          AlertMessage({
            message: this.props.t('userNotRemoved'),
            type: "error",
          });
        }
      })
      .catch((error) => { });
  };

  _addUsersToList = async (newUsers) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const reportId = this.state.iraId;
    var selectedUserIds = [];
    newUsers.map((item, key) => selectedUserIds.push(item.user_id));

    var data = {
      auth_key: token,
      user_id: userId,
      report_id: reportId,
      selected_user_id: selectedUserIds,
    };

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);

      if (property == "selected_user_id") {
        data[property].map((item, key) =>
          formBody.push(encodedKey + "=" + item)
        );
      } else {
        formBody.push(encodedKey + "=" + encodedValue);
      }
    }
    formBody = formBody.join("&");

    await axios
      .post(Url.smDashboard.addInviteUser, formBody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })

      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: this.props.t('userAdded'),
            type: "success",
          });
          this.reportDetail();
        } else {
          AlertMessage({
            message: this.props.t('userNotAdded'),
            type: "error",
          });
        }
      })
      .catch((error) => { });
  };

  cancelItem_onClick = () => {
    this.props.history.goBack();
    //window.history.back();
  };

  _showRecorder = async (flag = false, type = "observation") => {
    await this.setState((prevState) => {
      let { showRecorderModal, recordingFor } = prevState;
      showRecorderModal = flag;
      recordingFor = type;
      return { showRecorderModal, recordingFor };
    });
  };

  startRecording = async () => {
    await this.setState({ recordAudio: true });
  };

  stopRecording = async () => {
    await this.setState({ recordAudio: false });
  };

  onStop = async (recordedBlob) => {
    let promise = new Promise((resolve, error) => {
      let reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    promise.then(async (response) => {
      await this.setState((prevState) => {
        let {
          recordingFor,
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
          controlMeasureFileStatus,
          observationFileStatus,
        } = prevState;

        if (recordingFor === "control_measure") {
          controlMeasureAudio = recordedBlob.blob;
          controlMeasureURL = response;
          controlMeasureFileStatus = 2;
        }
        if (recordingFor === "observation") {
          observationAudio = recordedBlob.blob;
          observationURL = response;
          observationFileStatus = 2;
        }
        return {
          recordingFor,
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
          controlMeasureFileStatus,
          observationFileStatus,
        };
      });
    });
  };

  _removeAudioFile = async (type) => {
    await this.setState((prevState) => {
      if (type === "observation") {
        prevState.observationAudio = "";
        prevState.observationURL = "";
        return prevState;
      }

      if (type === "control_measure") {
        prevState.controlMeasureAudio = "";
        prevState.controlMeasureURL = "";
        return prevState;
      }
    });
  };

  _removeImageFile = async (value, type) => {
    if (type == "exist") {
      var array = this.state.evidenceImage;
      //added by Tushar for handling multiple images and remove functionality
      var deleteEvidences = this.state.deletedEvidencesIds;
      deleteEvidences.push(array[value].evidence_id);
      // var index = array.indexOf(value);
      array.splice(value, 1);
      this.setState({ evidenceImage: array, deleteFilesId: deleteEvidences });
    }
    if (type == "new") {
      var array = this.state.reportDetailevidence;
      //added by Tushar for handling multiple images and remove functionality
      var files = this.state.files;
      // var index = array.indexOf(value);
      array.splice(value, 1);
      files.splice(value, 1);
      this.setState({ reportDetailevidence: array, files });
    }
  };

  _onTimeChange = async (options) => {
    const { hour, minute, meridiem } = options;

    // const time = `${hour}:${minute}:00`;
    // const formattedTime = moment(time, "HH:mm:").format("hh:mm");

    const time = `${hour}:${minute}:00 ${meridiem}`;
    const formattedTime = moment(time, ["h:mm A"]).format("HH:mm");

    console.log("number == " + formattedTime);

    await this.setState({
      selectedIncidentTime: formattedTime,
      selectedTimeMeridiem: meridiem,
    });
  };

  _handleImageChange = async (e) => {
    e.preventDefault();

    let fileObj = [];
    let fileArray = this.state.reportDetailevidence;
    let files = this.state.files;
    fileObj.push(e.target.files);

    const lowStorage = this.checkLowStorage();
    lowStorage
      .then(async (response) => {
        const { status, msg } = response.data;

        if (status == 200) {
          for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push(fileObj[0][i]);
            //added by Tushar for handling multiple images and remove functionality
            let obj = {
              file: fileObj[0][i],
              file_status: 1,
              file_id: "",
            };
            files.push(obj);
          }
          this.setState({ reportDetailevidence: fileArray, files });
        }
        await this.setState({
          lowStorageStatus: status,
          lowStorageMessage: msg,
          showLowStoragePopup: status == 200 ? false : true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  addIncidentPerson = () => {
      if (this.state.injuredPersonName) {
        console.log("persons == " + this.state.injuredPersonName);
  
        let tempIncidentPersons = [...this.state.incidentPersons];
        const tempData = {
          incident_person_name: this.state.injuredPersonName.trim(),
          incident_person_id: this.state.injuredPersonID,
          status: "Created",
        };
  
        let newValue = [...tempIncidentPersons, tempData];
        this.setState({
          incidentPersons: newValue,
          injuredPersonName: "",
          injuredPersonID: "",
          incidentPersonNameError: false,
        });
      } else {
        ErrorToast("Please enter valid injured person name");
      }
    };

  UserWhoLiked = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetaillikelist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          report_type: "ira",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLike: response.data.data,
            likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => { });
  };

  _handleEditIncidentPersonName = async (event = "", index = 0, moduleName) => {
    let val = event.target.value;
    let tempIncidentPersonList = [...this.state.incidentPersonName];

    let item = tempIncidentPersonList[index];
    item[moduleName] = val;
    if (Object.hasOwn(item, "person_unique_record_id")) {
      item = {
        ...item,
        status: "Updated"
      }
    }
    tempIncidentPersonList[index] = item;

    this.setState({
      incidentPersonName: tempIncidentPersonList,
    });
  };

  _addInjuredPersonName = async (index = 0) => {
    if (this.state.injuredPersonName) {
      let tempIncidentPersonList = [...this.state.incidentPersonName];
      const tempData = {
        incident_person_name: this.state.injuredPersonName.trim(),
        incident_person_id: this.state.injuredPersonID,
        status: "Created"
      };

      let newValue = [...tempIncidentPersonList, tempData];
      this.setState({
        incidentPersonName: newValue,
        incidentPersonNameError: false,
        injuredPersonName: '',
        injuredPersonID: "",
      });
    } else {
      ErrorToast("Enter Valid Injured Employee Name");
    }
  };

  // _deletePersonName = async(deletePersonId) => {
  //   console.log("deletePersonId == "+id);
  //   this.setState({
  //     deletePersonId: id,
  //   });
  // }

  _deletePersonName = async (deletePersonId) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      id: deletePersonId,
    };

    let URL = Url.iraModule.deleteIncidentPerson;
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), ContentType)
      .then(async (response) => {
        if (response.data.status === 200) {

          let tempIncidentPerson = [...this.state.incidentPersonName];
          let newData = tempIncidentPerson.filter((item) => item.personId !== deletePersonId);

          tempIncidentPerson = newData;
          this.setState({
            incidentPersons: tempIncidentPerson,
          });
        }
      }).catch((error) => {

        AlertMessage({
          message: this.props.t('idCannotDeleted'),
          type: "error",
        });
      });

  }

  _removePerson = async (index) => {
    console.log("removePersonID == " + index);
    let updateIncidentPersons = this.state.incidentPersons.map((el, ind) => {
      if (ind === index) {
        el = {
          ...el,
          status: "Deleted",
        }
      }
      return el
    }
    );
    this.setState({
      incidentPersons: updateIncidentPersons,
    });
  }

  handleEditPersonName = async (event = "", index = 0, moduleName) => {
    let name = event.target.value;
    let tempIncidentPersons = [...this.state.incidentPersons];

    let item = tempIncidentPersons[index];
    item[moduleName] = name;
    if (Object.hasOwn(item, "person_unique_record_id")) {
      item = {
        ...item,
        status: "Updated",
      };
    }
    tempIncidentPersons[index] = item;
    this.setState({
      incidentPersons: tempIncidentPersons,
    });
  };


  searchEmployee = async (inputString, callback) => {
    const contentType = { "Content-Type": "application/x-www-form-urlencoded" };
    await axios
      .post(Url.userSearch, qs.stringify({
        service_id: this.state.reportDetail?.service_id,
        auth_key: localStorage.getItem("token"),
        project_id: this.state.reportDetail?.project_id,
        user_id: localStorage.getItem("userId"),
        search_user_name: inputString
      }), contentType)
      .then(async (response) => {
        if (response.data.status === 200) {
          callback(response.data)
          await this.setState({
            searchData: { ...this.state.searchData, ...response.data },
          });
        }
      })
      .catch((error) => {

      });
  }
  debounceFunc = _.debounce(function (callback) {
    callback()
  }, 300);
  render() {
    const {t} = this.props;
    var reportData = this.state.reportDetail;
    // if (this.state.redirectToReferrer) {
    //   return (
    //     <Redirect to={`/ira-detail/${reportData.ira_id}/kanban/pending`} />
    //   );
    // }
    const uaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.analysis?.unsafe_act,
              name: "Unsafe Act",
              color: "#C7AC83",
            },
            {
              y: reportData.analysis?.unsafe_condition,
              name: "Unsafe Condition",
              color: "#554040",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    const nmuaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.analysis?.nm_ua_number,
              name: "Unsafe Act",
              color: reportData.analysis?.nm_ua_color,
            },
            {
              y: reportData.analysis?.nm_uc_number,
              name: "Unsafe Condition",
              color: reportData.analysis?.nm_uc_color,
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    const analysisChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.analysis?.fa_number || 0,
              name: "FA",
              color: "#FF4242",
            },
            {
              y: reportData.analysis?.rta_number || 0,
              name: "RTA",
              color: "#086992",
            },
            {
              y: reportData.analysis?.mtc_number || 0,
              name: "MTC",
              color: "#FAAC15",
            },
            {
              y: reportData.analysis?.lti_number || 0,
              name: "LTI",
              color: "#18DA07",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    return (
      <div className="row  clide-v2-colume_title ">
          <div className="mb-1 clide-v2-fs-3 fw-bold">{t('iraEdit')} </div>

          <div className="row my-4 align-items-center">
            <div className="col-12 col-lg-4 col-md-4 px-0">
              <Link
                to={{
                  pathname: `/ira-detail/${reportData.ira_id}/kanban/pending`,
                }}
              >
                <Button
                  className="border clide-v2-primary clide-v2-white-color"
                  variant=""
                >
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                </Button>
              </Link>
            </div>
            <div className="col-12 col-lg-4 col-md-4 text-center">
              <span>{reportData.activity}</span>
            </div>
            <div className="col-12 col-lg-4 col-md-4 d-flex justify-content-end px-0">
              <div className="d-flex align-items-center">
                <button
                  className="clide-v2-icon-btn mr-2"
                  title="Add Team"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.EditreportDetail();
                    })
                  }
                >
                  <RoundSave />
                </button>
                <button
                  className="clide-v2-icon-btn mr-2"
                  title="Cancel"
                  onClick={() =>
                    this.debounceFunc(() => {
                      this.cancelItem_onClick();
                    })
                  }
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15.5"
                      fill="white"
                      stroke="#FF6347"
                    />
                    <path
                      d="M17.2727 16L20.8352 12.4375C21.0043 12.2687 21.0994 12.0397 21.0996 11.8008C21.0998 11.5619 21.0051 11.3327 20.8364 11.1636C20.6676 10.9945 20.4386 10.8994 20.1997 10.8992C19.9608 10.899 19.7316 10.9937 19.5625 11.1625L16 14.725L12.4375 11.1625C12.2684 10.9934 12.0391 10.8984 11.8 10.8984C11.5609 10.8984 11.3316 10.9934 11.1625 11.1625C10.9934 11.3316 10.8984 11.5609 10.8984 11.8C10.8984 12.0391 10.9934 12.2684 11.1625 12.4375L14.725 16L11.1625 19.5625C10.9934 19.7316 10.8984 19.9609 10.8984 20.2C10.8984 20.4391 10.9934 20.6684 11.1625 20.8375C11.3316 21.0066 11.5609 21.1016 11.8 21.1016C12.0391 21.1016 12.2684 21.0066 12.4375 20.8375L16 17.275L19.5625 20.8375C19.7316 21.0066 19.9609 21.1016 20.2 21.1016C20.4391 21.1016 20.6684 21.0066 20.8375 20.8375C21.0066 20.6684 21.1016 20.4391 21.1016 20.2C21.1016 19.9609 21.0066 19.7316 20.8375 19.5625L17.2727 16Z"
                      fill="#FF6347"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="col-12">
            <div className="row my-4 ">
              <div className="col-12 col-md-9 col-lg-9">
                <div className="clide-v2-card-box-1">
                  <div className="d-flex align-items-center">
                    <div className="fullWidth eight columns">
                      <ul className="cbp_tmtimeline">
                        <li>
                          <div className="cbp_tmicon cbp_tmicon-phone">
                            <svg
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M33.1716 14.3097C33.0926 14.1288 32.982 13.9634 32.845 13.8213L22.845 3.82134C22.7029 3.68431 22.5375 3.57369 22.3566 3.49467C22.3066 3.47134 22.2533 3.45801 22.2 3.43967C22.0605 3.39222 21.9153 3.36363 21.7683 3.35467C21.7333 3.35134 21.7016 3.33301 21.6666 3.33301H9.99996C8.16163 3.33301 6.66663 4.82801 6.66663 6.66634V33.333C6.66663 35.1713 8.16163 36.6663 9.99996 36.6663H30C31.8383 36.6663 33.3333 35.1713 33.3333 33.333V14.9997C33.3333 14.9647 33.315 14.933 33.3116 14.8963C33.3035 14.7492 33.2749 14.6039 33.2266 14.4647C33.21 14.4113 33.195 14.3597 33.1716 14.3097ZM27.6433 13.333H23.3333V9.02301L27.6433 13.333ZM9.99996 33.333V6.66634H20V14.9997C20 15.4417 20.1756 15.8656 20.4881 16.1782C20.8007 16.4907 21.2246 16.6663 21.6666 16.6663H30L30.0033 33.333H9.99996Z"
                                fill="white"
                              />
                              <path
                                d="M13.3334 19.9997H26.6667V23.333H13.3334V19.9997ZM13.3334 26.6663H26.6667V29.9997H13.3334V26.6663ZM13.3334 13.333H16.6667V16.6663H13.3334V13.333Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="row">
                                  <div className="col-6 px-0">
                                    <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold  pt-3">
                                      {t("activityInfo")}
                                    </p>
                                  </div>
                                  <div className="col-6 px-0">
                                    {/* <div
                                      className="severity-box"
                                      style={{
                                        backgroundColor:
                                          reportData.severity_color,
                                      }}
                                    ></div>
                                    <select
                                      value={this.state.selectedSeverity}
                                      className="form-control mb-0"
                                      onChange={(event) =>
                                        this._severityChangeHandle(
                                          event.target.value
                                        )
                                      }
                                    >
                                      <option>Select Risk Level</option>
                                      {SEVERITY.map((item, index) => (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      ))}
                                    </select> */}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col mb-2 px-0">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          {t('service')}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        <div className="output-text">
                                          {reportData.service}
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col px-0">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                        {t('plantProject')}{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        {reportData.project}
                                      </label>
                                    </div>
                                  </div>
                                  {/* {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "hazard"
                                  ) ? (
                                    <div className="col px-0">
                                      <div className="col-12 col-md-12 col-lg-12 px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            Hazard
                                          </label>
                                        </div>
                                      </div>
                                      <div className="clide-v2-title mb-1 d-flex align-items-baseline">
                                        <select
                                          className="form-control mb-0"
                                          value={
                                            this.state.selectedHazardId || ""
                                          }
                                          onChange={(event) =>
                                            this._hazardChangeHandle(
                                              event.target.value
                                            )
                                          }
                                        >
                                          <option value="0">
                                            Select Hazard
                                          </option>
                                          {this.state.hazardList.map(
                                            (item, index) => (
                                              <option
                                                value={item.id}
                                                key={`hazardList-${index}`}
                                              >
                                                {item.hazard}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        {this.state.isHazardInvalid ? (
                                          <p className="text-danger">
                                            Please select an option
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                  ) : null} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="cbp_tmicon cbp_tmicon-phone">
                            <svg
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M33.1716 14.3097C33.0926 14.1288 32.982 13.9634 32.845 13.8213L22.845 3.82134C22.7029 3.68431 22.5375 3.57369 22.3566 3.49467C22.3066 3.47134 22.2533 3.45801 22.2 3.43967C22.0605 3.39222 21.9153 3.36363 21.7683 3.35467C21.7333 3.35134 21.7016 3.33301 21.6666 3.33301H9.99996C8.16163 3.33301 6.66663 4.82801 6.66663 6.66634V33.333C6.66663 35.1713 8.16163 36.6663 9.99996 36.6663H30C31.8383 36.6663 33.3333 35.1713 33.3333 33.333V14.9997C33.3333 14.9647 33.315 14.933 33.3116 14.8963C33.3035 14.7492 33.2749 14.6039 33.2266 14.4647C33.21 14.4113 33.195 14.3597 33.1716 14.3097ZM27.6433 13.333H23.3333V9.02301L27.6433 13.333ZM9.99996 33.333V6.66634H20V14.9997C20 15.4417 20.1756 15.8656 20.4881 16.1782C20.8007 16.4907 21.2246 16.6663 21.6666 16.6663H30L30.0033 33.333H9.99996Z"
                                fill="white"
                              />
                              <path
                                d="M13.3334 19.9997H26.6667V23.333H13.3334V19.9997ZM13.3334 26.6663H26.6667V29.9997H13.3334V26.6663ZM13.3334 13.333H16.6667V16.6663H13.3334V13.333Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="row">
                                  <div className="col-12 px-0">
                                    <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold  pt-3">
                                      {t('aboutIncident')}
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "type of act"
                                  ) ? (
                                    <div className="col col-lg-4 col-md-4">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('typeAct')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="clide-v2-title mb-1 d-flex align-items-baseline">
                                        <select
                                          className="form-control"
                                          value={
                                            this.state.selectedTypeAct || ""
                                          }
                                          onChange={(event) =>
                                            this._typeactChangeHandle(
                                              event.target.value
                                            )
                                          }
                                        >
                                          <option value="0">Select Type</option>
                                          {this.state.typeActList.map(
                                            (item, index) => (
                                              <option
                                                value={item.id}
                                                key={`typeActList-${index}`}
                                              >
                                                {item.name}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  ) : null}

{CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "incident_type"
                                  ) ? (
                                    <div className="col px-0">
                                      <div className="col-12 col-md-12 col-lg-12 px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                         {t('incidentTypeFUC')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="clide-v2-title mb-1 d-flex align-items-baseline">
                                      <select
                          className="form-control mb-0"
                          value={this.state.selectedTypeIncidence || ""}
                          onChange={(event) =>
                            this._typeactChangeHandle(event.target.value)
                          }
                        >
                          <option value="0">{t('selectType')}</option>
                          {this.state.incidentTypeList.map((item, index) => (
                            <option
                              value={item.id}
                              key={`typeActList-${index}`}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                                        {this.state.isHazardInvalid ? (
                                          <p className="text-danger">
                                            {t('selectOption')}
                                          </p>
                                        ) : null}
                                      </div>
                                    </div>
                                  ) : null}

                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "activity"
                                  ) ? (
                                    <div className="col col-lg-4 col-md-4">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('activity')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="clide-v2-title mb-1 d-flex align-items-baseline">
                                        <select
                                          className="form-control"
                                          value={
                                            this.state.selectedActivity || ""
                                          }
                                          onChange={(event) =>
                                            this._activityChangeHandle(
                                              event.target.value,
                                              true,
                                              false
                                            )
                                          }
                                        >
                                          <option value="0">
                                            {t('selectActivity')}
                                          </option>
                                          {this.state.activityList.map(
                                            (item, index) => (
                                              <option
                                                value={item.activity_id}
                                                key={`activityList-${index}`}
                                              >
                                                {item.actvity}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  ) : null}

                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "incident date"
                                  ) ? (
                                    <div className="col col-lg-4 col-md-4">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('incidentDate')}
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex flex-direction-m">
                                        <div class="input-group mb-3  w-100">
                                          <DatePicker
                                            selected={
                                              this.state.selectedIncidentDate
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={this.state.selectedOldDate}
                                            placeholderText="DD-MM-YYYY"
                                            className="form-control "
                                            wrapperClassName="flex-grow-1"
                                            onChange={(jsDate) =>
                                              this._handleChange(
                                                jsDate,
                                                "incident_date"
                                              )
                                            }
                                          />
                                          <button
                                            class="btn btn-secondary clide-v2-bg-white clide-v2-border-color border-start-0"
                                            type="button"
                                            id="button-addon2"
                                          >
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M12.6668 2.66634H11.3335V1.99967C11.3335 1.82286 11.2633 1.65329 11.1382 1.52827C11.0132 1.40325 10.8436 1.33301 10.6668 1.33301C10.49 1.33301 10.3204 1.40325 10.1954 1.52827C10.0704 1.65329 10.0002 1.82286 10.0002 1.99967V2.66634H6.00016V1.99967C6.00016 1.82286 5.92992 1.65329 5.8049 1.52827C5.67988 1.40325 5.51031 1.33301 5.3335 1.33301C5.15668 1.33301 4.98712 1.40325 4.86209 1.52827C4.73707 1.65329 4.66683 1.82286 4.66683 1.99967V2.66634H3.3335C2.80306 2.66634 2.29436 2.87705 1.91928 3.25213C1.54421 3.6272 1.3335 4.13591 1.3335 4.66634V12.6663C1.3335 13.1968 1.54421 13.7055 1.91928 14.0806C2.29436 14.4556 2.80306 14.6663 3.3335 14.6663H12.6668C13.1973 14.6663 13.706 14.4556 14.081 14.0806C14.4561 13.7055 14.6668 13.1968 14.6668 12.6663V4.66634C14.6668 4.13591 14.4561 3.6272 14.081 3.25213C13.706 2.87705 13.1973 2.66634 12.6668 2.66634ZM13.3335 12.6663C13.3335 12.8432 13.2633 13.0127 13.1382 13.1377C13.0132 13.2628 12.8436 13.333 12.6668 13.333H3.3335C3.15668 13.333 2.98712 13.2628 2.86209 13.1377C2.73707 13.0127 2.66683 12.8432 2.66683 12.6663V7.99967H13.3335V12.6663ZM13.3335 6.66634H2.66683V4.66634C2.66683 4.48953 2.73707 4.31996 2.86209 4.19494C2.98712 4.06991 3.15668 3.99967 3.3335 3.99967H4.66683V4.66634C4.66683 4.84315 4.73707 5.01272 4.86209 5.13775C4.98712 5.26277 5.15668 5.33301 5.3335 5.33301C5.51031 5.33301 5.67988 5.26277 5.8049 5.13775C5.92992 5.01272 6.00016 4.84315 6.00016 4.66634V3.99967H10.0002V4.66634C10.0002 4.84315 10.0704 5.01272 10.1954 5.13775C10.3204 5.26277 10.49 5.33301 10.6668 5.33301C10.8436 5.33301 11.0132 5.26277 11.1382 5.13775C11.2633 5.01272 11.3335 4.84315 11.3335 4.66634V3.99967H12.6668C12.8436 3.99967 13.0132 4.06991 13.1382 4.19494C13.2633 4.31996 13.3335 4.48953 13.3335 4.66634V6.66634Z"
                                                fill="#FF6347"
                                              ></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}

                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "incident time"
                                  ) ? (
                                    <div className="col col-lg-4 col-md-4">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('incidentTm')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="calender-div slds-form-element__control">
                                        <TimePicker
                                          timeMode="12"
                                          onTimeChange={(timeOptions) =>
                                            this._onTimeChange(timeOptions)
                                          }
                                          time={this.state.selectedIncidentTime}
                                          closeOnOutsideClick={false}
                                          meridiem={
                                            this.state.selectedTimeMeridiem
                                          }
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "Employee Search"
                                  ) ? (
                                    <div className="col ">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('searchEmployee')}
                                          </label>
                                        </div>
                                      </div>
                                      <div>
                                        <AsyncSelect
                                          isMulti
                                          cacheOptions
                                          onChange={(data) => {
                                            const items = data?.map((el) => {
                                              return {
                                                incident_person_id:
                                                  el?.employeeId,
                                                incident_person_name: el?.label,
                                                status: "Created",
                                                searched_incident_person_id:
                                                  el?.value,
                                              };
                                            });
                                            this.setState({
                                              searchFiledUsers: items,
                                            });
                                          }}
                                          placeholder={t('enterEmployeeName')}
                                          loadOptions={(input, callback) => {
                                            if (this.timeout)
                                              clearTimeout(this.timeout);
                                            this.timeout = setTimeout(() => {
                                              this.searchEmployee(
                                                input,
                                                (data) => {
                                                  const excudleIds =
                                                    this.state?.incidentPersons?.map(
                                                      (el) => {
                                                        if (
                                                          el?.searched_incident_person_id &&
                                                          el?.status !==
                                                            "Deleted"
                                                        ) {
                                                          return el?.searched_incident_person_id?.toString();
                                                        }
                                                      }
                                                    );
                                                  const items = [];
                                                  data.data.forEach(
                                                    (element) => {
                                                      if (
                                                        !excudleIds.includes(
                                                          element?.id?.toString()
                                                        )
                                                      ) {
                                                        items.push({
                                                          ...element,
                                                          label: element?.name,
                                                          value: element?.id,
                                                        });
                                                      }
                                                    }
                                                  );
                                                  callback(items);
                                                }
                                              );
                                            }, 1000);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                  <Row className="px-0 mt-4">
                                    <Col className="px-0 ">
                                      <div className="feild-group">
                                        {this.state.incidentPersons?.map(
                                          (data, index) => {
                                            return data?.status !==
                                              "Deleted" ? (
                                              <div
                                                key={index}
                                                className="row d-flex align-items-baseline"
                                              >
                                                <div className="col col-md-5 col-lg-5">
                                                  {CheckFormSettingField(
                                                    this.state.formSetting
                                                      ?.setting,
                                                     "name of injured person"
                                                  ) ? (
                                                    <div>
                                                      <div className="clide-v2-title mb-1 ">
                                                        <label className="clide-v2-fs-4 mb-0 w-space-nobreak">
                                                          {t('nameInjuredPerson')}
                                                        </label>
                                                      </div>
                                                      <input
                                                        type="text"
                                                        className="form-control mb-0"
                                                        value={
                                                          data?.incident_person_name
                                                        }
                                                        disabled={
                                                          data?.searched_incident_person_id
                                                        }
                                                        onChange={(event) =>
                                                          this._handleEditIncidentPersonName(
                                                            event,
                                                            index,
                                                            "incident_person_name"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div className="col col-md-5 col-lg-5">
                                                  {CheckFormSettingField(
                                                    this.state.formSetting
                                                      ?.setting,
                                                    "person id"
                                                  ) ? (
                                                    <div>
                                                      <div className="clide-v2-title mb-1 ">
                                                        <label className="clide-v2-fs-4 mb-0 w-space-nobreak ">
                                                          {t("idAUC")}
                                                        </label>
                                                      </div>
                                                      <input
                                                        type="text"
                                                        className="form-control mb-0"
                                                        value={
                                                          data?.incident_person_id
                                                        }
                                                        disabled={
                                                          data?.searched_incident_person_id
                                                        }
                                                        onChange={(event) =>
                                                          this.handleEditPersonName(
                                                            event,
                                                            index,
                                                            "incident_person_id"
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div className="col">
                                                  <XCircle
                                                    className="cursor-pointer mt-4"
                                                    onClick={() =>
                                                      this._removePerson(index)
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            ) : null;
                                          }
                                        )}
                                      </div>
                                    </Col>
                                    <Col className="d-flex align-items-end">
                                      <div className="row d-flex align-items-baseline">
                                        <div className="col col-md-5 col-lg-5">
                                          <input
                                            type="text"
                                            className={`form-control  mb-0  ${
                                              this.state.incidentPersonNameError
                                                ? "is-invalid"
                                                : "border"
                                            }`}
                                            value={this.state.injuredPersonName}
                                            placeholder={t('enterPersonName')}
                                            onChange={(event) =>
                                              this.setState({
                                                injuredPersonName:
                                                  event.target.value,
                                              })
                                            }
                                          />
                                        </div>
                                        <div className="col col-md-5 col-lg-5">
                                          <input
                                            type="text"
                                            className={`form-control  mb-0 mx-2  ${
                                              this.state.incidentPersonNameError
                                                ? "is-invalid"
                                                : "border"
                                            }`}
                                            value={this.state.injuredPersonID}
                                            placeholder={t('enterId')}
                                            onChange={(event) =>
                                              this.setState({
                                                injuredPersonID:
                                                  event.target.value,
                                              })
                                            }
                                          />
                                          <ErrorBlock
                                            message={t('enterIncidentPersonName')}
                                          />
                                        </div>

                                        <div className="col ">
                                          <PlusCircle
                                            className="cursor-pointer"
                                            onClick={() =>
                                              this.addIncidentPerson()
                                            }
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="cbp_tmicon cbp_tmicon-phone">
                            <svg
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M33.1716 14.3097C33.0926 14.1288 32.982 13.9634 32.845 13.8213L22.845 3.82134C22.7029 3.68431 22.5375 3.57369 22.3566 3.49467C22.3066 3.47134 22.2533 3.45801 22.2 3.43967C22.0605 3.39222 21.9153 3.36363 21.7683 3.35467C21.7333 3.35134 21.7016 3.33301 21.6666 3.33301H9.99996C8.16163 3.33301 6.66663 4.82801 6.66663 6.66634V33.333C6.66663 35.1713 8.16163 36.6663 9.99996 36.6663H30C31.8383 36.6663 33.3333 35.1713 33.3333 33.333V14.9997C33.3333 14.9647 33.315 14.933 33.3116 14.8963C33.3035 14.7492 33.2749 14.6039 33.2266 14.4647C33.21 14.4113 33.195 14.3597 33.1716 14.3097ZM27.6433 13.333H23.3333V9.02301L27.6433 13.333ZM9.99996 33.333V6.66634H20V14.9997C20 15.4417 20.1756 15.8656 20.4881 16.1782C20.8007 16.4907 21.2246 16.6663 21.6666 16.6663H30L30.0033 33.333H9.99996Z"
                                fill="white"
                              />
                              <path
                                d="M13.3334 19.9997H26.6667V23.333H13.3334V19.9997ZM13.3334 26.6663H26.6667V29.9997H13.3334V26.6663ZM13.3334 13.333H16.6667V16.6663H13.3334V13.333Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="row">
                                  <div className="col-12 col-lg-6 col-md-6 px-0">
                                    <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold  pt-3">
                                      {t("viewEvidence")}
                                    </p>
                                  </div>
                                  <div className="col-12 col-lg-6 col-md-6 px-0 d-flex justify-content-end">
                                    <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold  pt-3">
                                      <Image className={"nm-mic"}></Image>
                                      <input
                                        type="file"
                                        multiple
                                        accept="image/*, video/*"
                                        name={`field.file_path`}
                                        className="file-upload__input"
                                        onChange={this._handleImageChange}
                                      />
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                  <div className="images-section">
        {this.state.reportDetail !== undefined && (
          <div className="d-flex gap-2 flex-wrap">
            {/* Existing Images */}
            {this.state.evidenceImage &&
              this.state.evidenceImage.map((data, index) => {
                if (data.evidence_format === 'image' || data.evidence_format === null) {
                  return (
                    <ImageCard
                      key={index}
                      imageUrl={data.evidence_image}
                      onDelete={() => this._removeImageFile(index, 'exist')}
                      onImageClick={() => this.setState({ lightboxactive: true })}
                    />
                  );
                } else {
                  return (
                    <VideoCard
                      key={index}
                      videoUrl={data.link}
                      onDelete={() => this._removeImageFile(index, 'exist')}
                    />
                  );
                }
              })}

            {/* New Uploaded Files */}
            {this.state.files &&
              this.state.files.map((fileData, index) => (
                <ImageCard
                  key={index}
                  imageUrl={URL.createObjectURL(fileData.file)}
                  onDelete={() => this._removeImageFile(index, 'new')}
                />
              ))}
          </div>
        )}
      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="cbp_tmicon cbp_tmicon-phone">
                            <svg
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M33.1716 14.3097C33.0926 14.1288 32.982 13.9634 32.845 13.8213L22.845 3.82134C22.7029 3.68431 22.5375 3.57369 22.3566 3.49467C22.3066 3.47134 22.2533 3.45801 22.2 3.43967C22.0605 3.39222 21.9153 3.36363 21.7683 3.35467C21.7333 3.35134 21.7016 3.33301 21.6666 3.33301H9.99996C8.16163 3.33301 6.66663 4.82801 6.66663 6.66634V33.333C6.66663 35.1713 8.16163 36.6663 9.99996 36.6663H30C31.8383 36.6663 33.3333 35.1713 33.3333 33.333V14.9997C33.3333 14.9647 33.315 14.933 33.3116 14.8963C33.3035 14.7492 33.2749 14.6039 33.2266 14.4647C33.21 14.4113 33.195 14.3597 33.1716 14.3097ZM27.6433 13.333H23.3333V9.02301L27.6433 13.333ZM9.99996 33.333V6.66634H20V14.9997C20 15.4417 20.1756 15.8656 20.4881 16.1782C20.8007 16.4907 21.2246 16.6663 21.6666 16.6663H30L30.0033 33.333H9.99996Z"
                                fill="white"
                              />
                              <path
                                d="M13.3334 19.9997H26.6667V23.333H13.3334V19.9997ZM13.3334 26.6663H26.6667V29.9997H13.3334V26.6663ZM13.3334 13.333H16.6667V16.6663H13.3334V13.333Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="row">
                                  <div className="col-12 px-0">
                                    <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold  pt-3">
                                      {t('observation')}
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <div className="feild-group rd-box">
                                      <textarea
                                        className="form-control"
                                        onChange={(event) =>
                                          this._handleChange(
                                            event.target.value,
                                            "observation"
                                          )
                                        }
                                        value={this.state.observationText}
                                      ></textarea>
                                    </div>
                                    <div className="feild-group rd-box">
                                      {this.state.observationURL != undefined &&
                                        this.state.observationURL.substr(
                                          this.state.observationURL.length - 3
                                        ) == ".mp3" && (
                                          <div className="audioPreviewer">
                                            <AudioPlayer
                                              src={this.state.observationURL}
                                              showJumpControls={true}
                                              layout="horizontal-reverse"
                                              customVolumeControls={[]}
                                              customAdditionalControls={[]}
                                              style={{
                                                width: "400px",
                                              }}
                                            />
                                            {/* <audio controls>
                                    <source
                                      src={this.state.observationURL}
                                    ></source>
                                  </audio> */}
                                            <Button
                                              variant="outline-link ml-2"
                                              onClick={() =>
                                                this._removeAudioFile(
                                                  "observation"
                                                )
                                              }
                                            >
                                              X
                                            </Button>
                                          </div>
                                        )}
                                      {this.state.observationAudio && (
                                        <div className="audioPreviewer">
                                          <AudioPlayer
                                            src={this.state.observationURL}
                                            showJumpControls={true}
                                            layout="horizontal-reverse"
                                            customVolumeControls={[]}
                                            customAdditionalControls={[]}
                                            style={{
                                              width: "400px",
                                            }}
                                          />
                                          <Button
                                            variant="outline-link ml-2"
                                            onClick={() =>
                                              this._removeAudioFile(
                                                "observation"
                                              )
                                            }
                                          >
                                            X
                                          </Button>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="cbp_tmicon cbp_tmicon-phone">
                            <svg
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M33.1716 14.3097C33.0926 14.1288 32.982 13.9634 32.845 13.8213L22.845 3.82134C22.7029 3.68431 22.5375 3.57369 22.3566 3.49467C22.3066 3.47134 22.2533 3.45801 22.2 3.43967C22.0605 3.39222 21.9153 3.36363 21.7683 3.35467C21.7333 3.35134 21.7016 3.33301 21.6666 3.33301H9.99996C8.16163 3.33301 6.66663 4.82801 6.66663 6.66634V33.333C6.66663 35.1713 8.16163 36.6663 9.99996 36.6663H30C31.8383 36.6663 33.3333 35.1713 33.3333 33.333V14.9997C33.3333 14.9647 33.315 14.933 33.3116 14.8963C33.3035 14.7492 33.2749 14.6039 33.2266 14.4647C33.21 14.4113 33.195 14.3597 33.1716 14.3097ZM27.6433 13.333H23.3333V9.02301L27.6433 13.333ZM9.99996 33.333V6.66634H20V14.9997C20 15.4417 20.1756 15.8656 20.4881 16.1782C20.8007 16.4907 21.2246 16.6663 21.6666 16.6663H30L30.0033 33.333H9.99996Z"
                                fill="white"
                              />
                              <path
                                d="M13.3334 19.9997H26.6667V23.333H13.3334V19.9997ZM13.3334 26.6663H26.6667V29.9997H13.3334V26.6663ZM13.3334 13.333H16.6667V16.6663H13.3334V13.333Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="row">
                                  <div className="col-12 px-0">
                                    <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold  pt-3">
                                      {t('departmentContrLocation')}
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "department"
                                  ) ? (
                                    <div className="col col-lg-4 col-md-4">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('department')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="clide-v2-title mb-1 d-flex align-items-baseline">
                                        <select
                                          className="form-control"
                                          value={
                                            this.state.selectedDepartmentId ||
                                            ""
                                          }
                                          onChange={(event) =>
                                            this._departmentChangeHandle(
                                              event.target.value
                                            )
                                          }
                                        >
                                          <option value="0">
                                            {t('selectDepartment')}
                                          </option>
                                          {this.state.departmentList.map(
                                            (item, index) => (
                                              <option
                                                value={item.id}
                                                key={`departmentList-${index}`}
                                              >
                                                {item.department}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  ) : null}

                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "contractor"
                                  ) ? (
                                    <div className="col col-lg-4 col-md-4">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('contractor')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="clide-v2-title mb-1 d-flex align-items-baseline">
                                        <select
                                          className="form-control"
                                          value={
                                            this.state.selectedContractorId ||
                                            ""
                                          }
                                          onChange={(event) =>
                                            this._contractorChangeHandle(
                                              event.target.value
                                            )
                                          }
                                        >
                                          <option value="0">
                                            {t('selectContractor')}
                                          </option>
                                          {this.state.contractorList.map(
                                            (item, index) => (
                                              <option
                                                value={item.id}
                                                key={`contractorList-${index}`}
                                              >
                                                {item.contractor}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  ) : null}

                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "zone"
                                  ) ? (
                                    <div className="col col-lg-4 col-md-4">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('zone')}
                                          </label>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="clide-v2-title mb-1 d-flex align-items-baseline">
                                          <select
                                            className="form-control"
                                            value={
                                              this.state.selectedZoneId || ""
                                            }
                                            onChange={(event) =>
                                              this._zoneChangeHandle(
                                                event.target.value
                                              )
                                            }
                                          >
                                            <option value="0">
                                              {t('selectZone')}
                                            </option>
                                            {this.state.zoneList.map(
                                              (item, index) => (
                                                <option
                                                  value={item.id}
                                                  key={`zoneList-${index}`}
                                                >
                                                  {item.zone}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "location"
                                  ) ? (
                                    <div className="col col-lg-4 col-md-4">
                                      <div className="col-12  px-0">
                                        <div className="clide-v2-title mb-1 ">
                                          <label className="clide-v2-fs-4 mb-0 ">
                                            {t('location')}
                                          </label>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="clide-v2-title mb-1 d-flex align-items-baseline">
                                          <select
                                            className="form-control"
                                            value={
                                              this.state.selectedLocationId ||
                                              ""
                                            }
                                            onChange={(event) =>
                                              this._locationChangeHandle(
                                                event.target.value
                                              )
                                            }
                                          >
                                            <option value="0">
                                              {t('selectLocation')}
                                            </option>
                                            {this.state.locationList.map(
                                              (item, index) => (
                                                <option
                                                  value={item.id}
                                                  key={`locationList-${index}`}
                                                >
                                                  {item.location}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>

                        <li>
                          <div className="cbp_tmicon cbp_tmicon-phone">
                            <svg
                              width={40}
                              height={40}
                              viewBox="0 0 40 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M33.1716 14.3097C33.0926 14.1288 32.982 13.9634 32.845 13.8213L22.845 3.82134C22.7029 3.68431 22.5375 3.57369 22.3566 3.49467C22.3066 3.47134 22.2533 3.45801 22.2 3.43967C22.0605 3.39222 21.9153 3.36363 21.7683 3.35467C21.7333 3.35134 21.7016 3.33301 21.6666 3.33301H9.99996C8.16163 3.33301 6.66663 4.82801 6.66663 6.66634V33.333C6.66663 35.1713 8.16163 36.6663 9.99996 36.6663H30C31.8383 36.6663 33.3333 35.1713 33.3333 33.333V14.9997C33.3333 14.9647 33.315 14.933 33.3116 14.8963C33.3035 14.7492 33.2749 14.6039 33.2266 14.4647C33.21 14.4113 33.195 14.3597 33.1716 14.3097ZM27.6433 13.333H23.3333V9.02301L27.6433 13.333ZM9.99996 33.333V6.66634H20V14.9997C20 15.4417 20.1756 15.8656 20.4881 16.1782C20.8007 16.4907 21.2246 16.6663 21.6666 16.6663H30L30.0033 33.333H9.99996Z"
                                fill="white"
                              />
                              <path
                                d="M13.3334 19.9997H26.6667V23.333H13.3334V19.9997ZM13.3334 26.6663H26.6667V29.9997H13.3334V26.6663ZM13.3334 13.333H16.6667V16.6663H13.3334V13.333Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12 ">
                                <div className="row">
                                  <div className="col-12 px-0">
                                    <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold  pt-3">
                                      {t('actionTarget')}
                                    </p>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col mb-2 px-0">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          {t('reportedBy')}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        <div className="output-text">
                                          {reportData.reported_by == "" ||
                                          reportData.reported_by ==
                                            undefined ? (
                                            <p className="na-text">NA</p>
                                          ) : (
                                            <div className="output-text">
                                              {reportData.reported_by}
                                            </div>
                                          )}
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col mb-2 px-0">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          {t('reportedDate')}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        <div className="output-text">
                                          {reportData.reported_date == "" ||
                                          reportData.reported_date ==
                                            undefined ? (
                                            <p className="na-text">NA</p>
                                          ) : (
                                            <div className="output-text">
                                              {reportData.reported_date}
                                              {/* {`${convertDateFromTimezone(reportData.reported_date)}`} */}
                                            </div>
                                          )}
                                        </div>
                                      </label>
                                    </div>
                                  </div>
                                  {reportData.assign_to ? (
                                    <div className="col mb-2 px-0">
                                      <div className="col-12 col-md-12 col-lg-12 px-0">
                                        <div className="clide-v2-title mb-1">
                                          <label className="clide-v2-fs-4 mb-0">
                                            {t('assignedTo')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="clide-v2-title mb-1">
                                        <label className="">
                                          <div className="output-text">
                                            {reportData.assign_to == "" ||
                                            reportData.assign_to ==
                                              undefined ? (
                                              <p className="na-text">NA</p>
                                            ) : (
                                              <div className="output-text">
                                                {reportData.assign_to}
                                              </div>
                                            )}
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  ) : null}

                                  {CheckFormSettingField(
                                    this.state.formSetting?.setting,
                                    "target date"
                                  ) ? (
                                    <div className="col mb-2 px-0">
                                      <div className="col-12 col-md-12 col-lg-12 px-0">
                                        <div className="clide-v2-title mb-1">
                                          <label className="clide-v2-fs-4 mb-0">
                                            {t('targetDate')}
                                          </label>
                                        </div>
                                      </div>
                                      <div class="d-flex flex-direction-m">
                                        <div class="input-group mb-3  w-100">
                                          <DatePicker
                                            selected={
                                              this.state.selectedTargetDate
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            maxDate={this.state.selectedOldDate}
                                            className="form-control "
                                            wrapperClassName="flex-grow-1"
                                            onChange={(jsDate) =>
                                              this._handleChange(
                                                jsDate,
                                                "target_date"
                                              )
                                            }
                                          />
                                          <button
                                            class="btn btn-secondary clide-v2-bg-white clide-v2-border-color border-start-0"
                                            type="button"
                                            id="button-addon2"
                                          >
                                            <svg
                                              width="16"
                                              height="16"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M12.6668 2.66634H11.3335V1.99967C11.3335 1.82286 11.2633 1.65329 11.1382 1.52827C11.0132 1.40325 10.8436 1.33301 10.6668 1.33301C10.49 1.33301 10.3204 1.40325 10.1954 1.52827C10.0704 1.65329 10.0002 1.82286 10.0002 1.99967V2.66634H6.00016V1.99967C6.00016 1.82286 5.92992 1.65329 5.8049 1.52827C5.67988 1.40325 5.51031 1.33301 5.3335 1.33301C5.15668 1.33301 4.98712 1.40325 4.86209 1.52827C4.73707 1.65329 4.66683 1.82286 4.66683 1.99967V2.66634H3.3335C2.80306 2.66634 2.29436 2.87705 1.91928 3.25213C1.54421 3.6272 1.3335 4.13591 1.3335 4.66634V12.6663C1.3335 13.1968 1.54421 13.7055 1.91928 14.0806C2.29436 14.4556 2.80306 14.6663 3.3335 14.6663H12.6668C13.1973 14.6663 13.706 14.4556 14.081 14.0806C14.4561 13.7055 14.6668 13.1968 14.6668 12.6663V4.66634C14.6668 4.13591 14.4561 3.6272 14.081 3.25213C13.706 2.87705 13.1973 2.66634 12.6668 2.66634ZM13.3335 12.6663C13.3335 12.8432 13.2633 13.0127 13.1382 13.1377C13.0132 13.2628 12.8436 13.333 12.6668 13.333H3.3335C3.15668 13.333 2.98712 13.2628 2.86209 13.1377C2.73707 13.0127 2.66683 12.8432 2.66683 12.6663V7.99967H13.3335V12.6663ZM13.3335 6.66634H2.66683V4.66634C2.66683 4.48953 2.73707 4.31996 2.86209 4.19494C2.98712 4.06991 3.15668 3.99967 3.3335 3.99967H4.66683V4.66634C4.66683 4.84315 4.73707 5.01272 4.86209 5.13775C4.98712 5.26277 5.15668 5.33301 5.3335 5.33301C5.51031 5.33301 5.67988 5.26277 5.8049 5.13775C5.92992 5.01272 6.00016 4.84315 6.00016 4.66634V3.99967H10.0002V4.66634C10.0002 4.84315 10.0704 5.01272 10.1954 5.13775C10.3204 5.26277 10.49 5.33301 10.6668 5.33301C10.8436 5.33301 11.0132 5.26277 11.1382 5.13775C11.2633 5.01272 11.3335 4.84315 11.3335 4.66634V3.99967H12.6668C12.8436 3.99967 13.0132 4.06991 13.1382 4.19494C13.2633 4.31996 13.3335 4.48953 13.3335 4.66634V6.66634Z"
                                                fill="#FF6347"
                                              ></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-3 col-md-3">
                <div className="row row-gap-4">
                  <div className="col-12">
                    <div className="clide-v2-card-box-1">
                      <div className="p-3 ">
                        <div className="d-flex-center">
                          <Button
                            variant=""
                            className="border clide-v2-primary-color font-weight-bolder"
                          >
                            {t('usersLiked')}
                          </Button>
                        </div>
                        <div className="rd-left-bottom side-invite-user">
                          <div className="d-flex justify-content-between align-items-baseline">
                            <span className="clide-v2-fs-4 clide-v2-primary-color fw-bold  pt-3">
                              {t('all')}
                            </span>
                            <span className="count mx-0">
                              {this.state.likeNumber}
                            </span>
                          </div>

                          {this.state.userLike != "" ? (
                            this.state.userLike?.map((items, index) => (
                              <div className="user-list" key={index}>
                                <img
                                  src={items.user_profile}
                                  alt={items.user_name}
                                  className="clide-v2-profile-img"
                                />
                                <h6 className="user-name">{items.user_name}</h6>
                              </div>
                            ))
                          ) : (
                            <p className="my-3 text-center border p-3 clide-v2-primary rounded-4 clide-v2-white-color">
                              {t('likesNotAvl')}{" "}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="clide-v2-card-box-1 p-3 mt-2">
                      <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                        {t('investigationTeam')}
                      </p>
                      <hr />

                      <div className="d-flex flex-wrap gap-2">
                        {reportData.people_involved_list
                          ?.length
                          ? reportData.people_involved_list.map(
                              (item, index) => (
                                <div>
                                  <div
                                    className=" align-items-center border d-flex justify-content-between pr-3 rounded-5 "
                                    key={`investigation-team-${index}`}
                                  >
                                    <button
                                      type="button"
                                      className="icon-button"
                                      title={item.user_name}
                                    >
                                      <span className="">
                                        {getInitials(item.user_name)}
                                      </span>
                                    </button>
                                    &nbsp;{" "}
                                    <label className="font-weight-bold mb-0 mx-2">
                                      {item.user_name ?? "NA"}{" "}
                                    </label>
                                  </div>
                                </div>
                              )
                            )
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="clide-v2-card-box-1 p-3 mt-2">
                      <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                        {reportData.activity} |{t(' Analysis')}
                      </p>
                      <hr />
                      {reportData.analysis?.fa_number == 0 &&
                      reportData.analysis?.mtc_number == 0 &&
                      reportData.analysis?.rta_number == 0 &&
                      reportData.analysis?.lti_number == 0 ? (
                        <div className="graph-not-available-text">
                          {t('graphNotAvl')}
                        </div>
                      ) : (
                        <div className="col-12  mb-3">
                          <div className="clide-v2-white-box">
                            <div className="row align-items-center">
                              <div className="col-12  my-2">
                                <div className="sm-graph-modal-legend-text text-center my-2">
                                  <b>{t('iraAnalysisAUC')}</b>
                                </div>

                                <div>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={analysisChart}
                                  />
                                </div>
                              </div>
                              <div className="col-12  ">
                                <div className="outer-legend-block">
                                  <Scrollbars
                                    style={{
                                      height: `6rem`,
                                      margin: "0.5rem 0.2rem 0.5rem 0",
                                    }}
                                    renderView={(props) => (
                                      <div
                                        style={{
                                          paddingRight: "15px",
                                          ...props.style,
                                          overflowX: "hidden",
                                        }}
                                      />
                                    )}
                                  >
                                    <div className="clide-v2-graph-left-box  d-flex align-items-center  ">
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span "
                                        style={{ backgroundColor: "#FF4242" }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text ">
                                        {t('fA')}
                                      </span>
                                      <span className=" ml-auto">
                                        {
                                          reportData?.analysis
                                            ?.fa_number
                                        }
                                      </span>
                                    </div>
                                    <div className="soi-kanban-inner-legend-block  ">
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span"
                                        style={{ backgroundColor: "#086992" }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text my-2">
                                        {t('mtC')}
                                      </span>
                                      <span className="legend-number float-end">
                                        {
                                          reportData?.analysis
                                            ?.mtc_number
                                        }
                                      </span>
                                    </div>
                                    <div className="soi-kanban-inner-legend-block  ">
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span"
                                        style={{ backgroundColor: "#FAAC15" }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text my-2">
                                        {t('rta')}
                                      </span>
                                      <span className="legend-number float-end">
                                        {
                                          reportData?.analysis
                                            ?.rta_number
                                        }
                                      </span>
                                    </div>
                                    {/* /* // Britannia v01
  //  Making this change to ...(Integrate this rta to tra and faid oi notable integration because of britian custoimization ) */}

                                    <div className="soi-kanban-inner-legend-block  ">
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span"
                                        style={{ backgroundColor: "#18DA07" }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text my-2">
                                        {t('ltI')}
                                      </span>
                                      <span className="legend-number float-end">
                                        {
                                          reportData?.analysis
                                            ?.lti_number
                                        }
                                      </span>
                                    </div>
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {reportData.analysis?.nm_ua_number == 0 &&
                      reportData.analysis?.nm_uc_number == 0 ? (
                        <div className="graph-not-available-text">
                          {t('graphNotAvl')}
                        </div>
                      ) : (
                        <div className="col-12  mb-3">
                          <div className="clide-v2-white-box">
                            <div className="row align-items-center">
                              <div className="col-12  my-2">
                                <div className="sm-graph-modal-legend-text text-center my-2">
                                  <b>{t('nmAnalysis')}</b>
                                </div>

                                <div>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={nmuaUcChart}
                                  />
                                </div>
                              </div>
                              <div className="col-12  ">
                                <div className="outer-legend-block">
                                  <Scrollbars
                                    style={{
                                      height: `6rem`,
                                      margin: "0.5rem 0.2rem 0.5rem 0",
                                    }}
                                    renderView={(props) => (
                                      <div
                                        style={{
                                          paddingRight: "15px",
                                          ...props.style,
                                          overflowX: "hidden",
                                        }}
                                      />
                                    )}
                                  >
                                    <div className="clide-v2-graph-left-box  d-flex align-items-center  ">
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span "
                                        style={{
                                          backgroundColor:
                                            reportData.analysis
                                              ?.nm_ua_color,
                                        }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text ">
                                        {t('unsafeAct')}
                                      </span>
                                      <span className=" ml-auto">
                                        {
                                          reportData.analysis
                                            ?.nm_ua_number
                                        }
                                      </span>
                                    </div>
                                    <div className="soi-kanban-inner-legend-block  ">
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span"
                                        style={{
                                          backgroundColor:
                                            reportData.analysis
                                              ?.nm_uc_color,
                                        }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text my-2">
                                        {t('unsafeCondition')}
                                      </span>
                                      <span className="legend-number float-end">
                                        {
                                          reportData.analysis
                                            ?.nm_uc_number
                                        }
                                      </span>
                                    </div>
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {reportData.analysis?.ua_number == 0 &&
                      reportData.analysis?.uc_number == 0 ? (
                        <div className="graph-not-available-text">
                          {t('graphNotAvl')}
                        </div>
                      ) : (
                        <div className="col-12  mb-3">
                          <div className="clide-v2-white-box">
                            <div className="row align-items-center">
                              <div className="col-12  my-2">
                                <div className="sm-graph-modal-legend-text text-center my-2">
                                  <b>{t('uaucAnalysis')}</b>
                                </div>

                                <div>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={uaUcChart}
                                  />
                                </div>
                              </div>
                              <div className="col-12  ">
                                <div className="outer-legend-block">
                                  <Scrollbars
                                    style={{
                                      height: `6rem`,
                                      margin: "0.5rem 0.2rem 0.5rem 0",
                                    }}
                                    renderView={(props) => (
                                      <div
                                        style={{
                                          paddingRight: "15px",
                                          ...props.style,
                                          overflowX: "hidden",
                                        }}
                                      />
                                    )}
                                  >
                                    <div className="clide-v2-graph-left-box  d-flex align-items-center  ">
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span "
                                        style={{ backgroundColor: "#C7AC83" }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text ">
                                        {t('unsafeAct')}
                                      </span>
                                      <span className=" ml-auto">
                                        {
                                          reportData.analysis
                                            ?.unsafe_act
                                        }
                                      </span>
                                    </div>
                                    <div className="soi-kanban-inner-legend-block  ">
                                      <span
                                        className="soi-kanban-modal-legend-box clide-v2-square-span"
                                        style={{ backgroundColor: "#554040" }}
                                      ></span>
                                      <span className="sm-graph-modal-legend-text my-2">
                                        {t('unsafeCondition')}
                                      </span>
                                      <span className="legend-number float-end">
                                        {
                                          reportData.analysis
                                            ?.unsafe_condition
                                        }
                                      </span>
                                    </div>
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withTranslation(['ira','util'])(withRouter(IraEditReportingDetail));
